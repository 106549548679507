<template>
  <b-modal id="modal-method" ref="modalMethod" size="lg" centered hide-backdrop hide-footer content-class="shadow" ok-only no-stacking>
    <div class="row mt-10">
      <div class="col-12 col-md-5 px-10">
        <h3 class="text-primary">{{$t("LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION")}}</h3>
<!--        <p class="lead text-muted font-weight-bold">-->
<!--          {{$t("LOGINREGISTERPROCESSONPUBLIC.PAYOFF") /* #key_loc */}}-->
<!--        </p>-->
        <p class="mt-6 font-weight-bolder">{{$t("ADVAUTHMETHOD.PARTECIPAPROCESSO")}}</p>
        <img
            :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
            class="h-60px my-4"
            alt="Logo"
        />
        <div class="h5 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>
      </div>
      <div class="col-12 col-md-7 border-left px-10">
          <!-- ACCEDI -->
          <router-link to="" v-b-modal.modal-login-on-public>
            <div class="d-flex align-items-center mb-4 bg-light-primary rounded p-5">
              <span class="svg-icon svg-icon-warning mr-5">
                <span class="svg-icon svg-icon-lg">
                  <img
                    src="/assets/media/bipart/ico-patti.png"
                    class="w-70px"
                    alt="Logo"
                  />
                </span>
              </span>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <h6 class="font-weight-bold text-dark-75 text-hover-primary mb-1">{{$t("ADVAUTHMETHOD.ACCEDICOMM1")}} </h6>
                <span>{{$t("ADVAUTHMETHOD.ACCEDICOMM2")}}</span>
              </div>
            </div>
          </router-link>

        <!-- REGISTRATI -->
        <!-- <router-link to="" v-b-modal.modal-register-on-public>
          <div class="d-flex align-items-center mb-4 bg-light-primary rounded p-5">
            <span class="svg-icon svg-icon-warning mr-5">
              <span class="svg-icon svg-icon-lg">
                <img
                  src="/assets/media/bipart/ico-patti.png"
                  class="w-70px"
                  alt="Logo"
                />
              </span>
            </span>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <h6 class="font-weight-bold text-dark-75 text-hover-primary mb-1">{{$t("ADVAUTHMETHOD.REGISTRATICOMM1")}}</h6>
              <span>{{$t("ADVAUTHMETHOD.REGISTRATICOMM2")}}</span>
            </div>
          </div>
        </router-link> -->

        <!-- REGISTRA I DATI PER IL VOTO -->
        <router-link to="" v-b-modal.modal-authRole>
          <div class="d-flex align-items-center mb-4 bg-light-primary rounded p-5">
            <span class="svg-icon svg-icon-warning mr-5">
              <span class="svg-icon svg-icon-lg">
                <img
                    src="/assets/media/bipart/ico-patti.png"
                    class="w-70px"
                    alt="Logo"
                />
              </span>
            </span>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <h6 class="font-weight-bold text-dark-75 text-hover-primary mb-1">{{$t("ADVAUTHMETHOD.SOLOVOTO")}}</h6>
              <span>{{$t("ADVAUTHMETHOD.SOLOVOTO2")}} </span>
            </div>
          </div>
        </router-link>

      </div>
    </div>
  </b-modal>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "advMethodAuth",
  computed: {
    ...mapGetters([
      "getOrganization",
      "currentUser"
    ]),
  },
}
</script>

<style scoped>

</style>
