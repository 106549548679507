<template>
  <div class="row h-100">
    <div class="d-none d-lg-block col-lg-2 col-xl-2 bg-white">
      <div class="pl-4">
        <Phaseline :phases="process.processPhases" :processId="process.id" :processLogo="getProcessLogo">
        </Phaseline>
      </div>
    </div>
    <div class="col-lg-9 col-xl-9">
      <!-- Header BP + Fase -->
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-column mt-5 mx-5">
            <!--Versioning alert 0
            <div v-if="activeVersioning()" class="alert alert-custom alert-2x bg-primary fade show mb-5 py-2" role="alert">
              <div class="row align-items-center w-100">

                <div class="col-1 p-0 m-0 align-items-center text-center">
                  <i class="fas fa-layer-group text-white" style="font-size: 3em;"></i>
                </div>

                <div class="col-12 col-md-7 p-0 m-0 mb-3 mb-md-0 align-items-center">
                  <div class="alert-text text-uppercase font-weight-bolder ">
                    <h5 class="aling-items-center mt-2 text-white">
                      {{$t("IDEADETAIL.ALERTVERSIONING1") /* #key_loc */}}
                    </h5>
                    <span class="aling-items-center mt-2 text-white">
                      {{$t("IDEADETAIL.ALERTVERSIONING2") /* #key_loc */}}
                    </span>
                  </div>
                </div>

                <div v-if="this.maxVersionsPerContent>0" class="col-6 col-md-4 m-0 text-right">
                  <h3 class="text-white">{{this.maxVersionsPerContent}}</h3>
                  <span class="text-uppercase text-white">{{$t("IDEADETAIL.ALERTVERSIONING3") /* #key_loc */}}</span>
                </div>
                <div v-else class="col-6 col-md-4 m-0 text-right"></div>

                <div v-if="true==false" class="col-6 col-md-2 m-0 text-right">
                  <h3 class="text-white">{{this.maxCompletionDaysPerVersionDate}}</h3>
                  <span class="ml-2 text-uppercase text-white">{{$t("IDEADETAIL.ALERTVERSIONING4") /* #key_loc */}}</span>
                </div>

              </div>
            </div>
            end::Versioning alert 0-->

            <!--::Process Info -->
            <div
              class="card mb-3 card-stretch shadow-sm ribbon ribbon-clip ribbon-right rounded card shadow-sm border-0 justify-content-center bgi-size-cover bgi-no-repeat"
              :style="{ 'background-image': 'url(' + header_background + ')' }">
              <!--Ribbon right-->
              <div v-if="phase.phaseStatus" class="ribbon-target p-3" style="bottom: 20px">
                <span class="ribbon-inner" v-bind:class="{
                  'bg-primary': phase.phaseStatus.id == 2,
                  'bg-warning': phase.phaseStatus.id == 1,
                  'bg-danger': phase.phaseStatus.id == 3,
                }"></span><span class="text-uppercase font-weight-bolder">{{
  phase.phaseStatus.name
}}</span>
              </div>
              <!--end::Ribbon right-->

              <div class="card-header bg-transparent border-0 d-flex justify-content-between pt-5 pb-2">
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <span v-if="!process.publiclyVisible" class="label label-inline label-xl mr-2" v-bind:class="{
                      'bg-primary': process.publiclyVisible,
                      'bg-danger': !process.publiclyVisible,
                    }">
                      <i class="fas fa-lock text-white"></i>
                    </span>

                    <span v-if="phase.openDate">
                      {{ $t("GENERALLABEL.DAL") | capitalize }}
                      <b class="font-weight-bolder h5">{{
                        phase.openDate | moment("DD/MM/Y")
                      }}</b>
                    </span>
                    <span class="ml-4" v-if="phase.closeDate">
                      {{ $t("GENERALLABEL.AL") }}
                      <b class="font-weight-bolder h5">{{
                        phase.closeDate | moment("DD/MM/Y")
                      }}</b></span>
                  </div>
                </div>
              </div>
              <div class="card-body pt-3">
                <h1 v-if="process &&
                    process.localizations &&
                    process.localizations.length > 0
                    " class="text-primary font-weight-bolder mb-3">
                  <router-link :to="{
                    name: 'processDetail',
                    params: { id: process.id },
                  }" class="text-primary" v-if="hasProcessManyPhases">
                    {{
                      process.localizations
                      ? process.localizations[0].title
                      : "---"
                    }}
                  </router-link>
                  <!--                <span v-else-if="process.localizations">{{ process.localizations[0].title }}</span>-->
                  <span v-else>
                    <router-link :to="{
                      name: 'processPhase',
                      params: {
                        process_id: process.id,
                        phase_id: phase.id,
                      },
                    }" class="text-primary" v-if="hasPhaseManyModules">
                      {{
                        process.localizations
                        ? process.localizations[0].title
                        : "---"
                      }}
                    </router-link>

                    <router-link :to="{
                          name: 'processModuleIdeaList',
                          params: {
                            id: module.id,
                            process_id: process.id,
                            phase_id: phase.id,
                          },
                        }" class="text-primary" v-else>
                      {{
                        process.localizations
                        ? process.localizations[0].title
                        : "---"
                      }}
                    </router-link>
                  </span>
                </h1>
                <div class="text-dark h2 font-weight-bolder mb-3">
                  <span class="label label-circle bg-primary text-white p-6 h3 font-weight-bolder mr-5"
                    v-if="process.countProcessPhases > 1">
                    {{ phase.seqStrict }} </span>{{ phase.title }}
                </div>
              </div>
            </div>

            <!--Module alert-->
            <div class="alert alert-custom alert-2x bg-primary fade show my-2 py-2" role="alert">
              <div class="row alert-text text-uppercase font-weight-bolder align-items-center">
                <div class="col-12 col-md-9 align-items-center">
                  <router-link :to="{
                    name: 'processModuleIdeaList',
                    params: {
                      id: module.id,
                      process_id: module.process.id,
                      phase_id: phase.id,
                    },
                  }" v-bind:id="module.title" v-slot="{ href, navigate }">
                    <a :href="href" @click="navigate">
                      <h5 class="aling-items-center mt-2 text-white">
                        {{ module.title }}
                      </h5>
                    </a>
                  </router-link>
                </div>

                <div class="col-12 col-md-3 align-items-center text-right">
                  <h6 class="aling-items-center mt-2 text-white">
                    {{ moduleDateStr }}
                  </h6>
                </div>
              </div>
            </div>
            <!--end::Module alert-->

            <!--Versioning alert 1-->
            <div class="alert alert-custom alert-2x bg-secondary fade show my-2 py-2" role="alert" v-if="idea.ideaStatus.id == 3 &&
              this.existsUnpubVersionInGroup &&
              !isAuthor() &&
              this.see_tabs &&
              true == false
              ">
              <div class="alert-text text-uppercase font-weight-bolder">
                <h5 class="aling-items-center mt-2 text-white">
                  <i class="fas fa-layer-group text-white"></i>
                  {{
                    $t(
                      $handleContentTypeLabel(moduleContentType) +
                      "DETAIL.ALERTPUBBLICATA"
                    )
                  }}
                </h5>
              </div>
            </div>
            <!--end::Versioning alert-->

            <!--Versioning alert 2-->
            <div class="alert alert-custom alert-2x bg-danger fade show my-2 py-2" role="alert" v-if="idea.ideaStatus.id == 3 &&
                this.existsUnpubVersionInGroup &&
                isAuthor() &&
                this.see_tabs &&
                this.module &&
                this.module.canVersion
                ">
              <div class="alert-text text-uppercase font-weight-bolder">
                <h5 class="aling-items-center mt-2 text-white">
                  <i class="fas fa-layer-group text-white"></i>
                  {{
                    $t(
                      $handleContentTypeLabel(moduleContentType) +
                      "DETAIL.ALERTBOZZA"
                    )
                  }}
                </h5>
              </div>
            </div>
            <!--end::Versioning alert-->
          </div>
        </div>
      </div>

      <!--:: TOOLS MOBILE-->
      <div class="d-md-block d-lg-none mx-6 my-2">
        <!--:: Commenta -->
        <div v-if="canComment() && this.see_tabs == true" class="btn-group mr-1" role="group">
          <a class="bg-transparent" @click="scrollMeToComment('tabs')">
            <ButtonMobile icon-class="fas fa-comment text-white" />
          </a>
        </div>

        <!--:: Follow idea-->
        <template v-if="canFollow && this.see_tabs == true">
          <div v-if="is_anonymous_on_public" class="btn-group mr-1 ml-1" role="group">
            <!-- Gestione registrazione avanzata follow -->
            <div v-if="this.module.advancedRegistrationRequiredForFollowing">
              <!--              #qui mobile -->

              <a class="bg-transparent" @click="advIncFollower($event)">
                <ButtonMobile icon-class="fas fa-heart text-white" />
              </a>
            </div>
            <div v-else>
              <!-- Gestione registrazione base follow -->
              <a v-b-modal.modalLoginRegisterProcessOnPublic class="bg-transparent">
                <ButtonMobile icon-class="fas fa-heart text-white" />
              </a>
            </div>
          </div>

          <div v-else-if="!is_anonymous_on_public && is_mobile_pending && is_email_pending
            " class="btn-group mr-1 ml-1" role="group">
            <a @click.prevent="alertEmailMobileConfirm" class="bg-transparent">
              <ButtonMobile icon-class="fas fa-heart text-white" />
            </a>
          </div>

          <div v-else-if="!is_anonymous_on_public &&
            is_mobile_pending &&
            !is_email_confirmed
            " class="btn-group mr-1 ml-1" role="group">
            <a @click.prevent="alertMobileConfirm" class="bg-transparent">
              <ButtonMobile icon-class="fas fa-heart text-white" />
            </a>
          </div>

          <div v-else-if="!is_anonymous_on_public &&
            is_email_pending &&
            !is_mobile_confirmed
            " class="btn-group mr-1 ml-1" role="group">
            <a @click.prevent="alertEmailConfirm" class="bg-transparent">
              <ButtonMobile icon-class="fas fa-heart text-white" />
            </a>
          </div>

          <div v-else-if="!is_anonymous_on_public && !userHasProcess(this.process.id)
            " class="btn-group mr-1 ml-1" role="group">
            <a v-b-modal.modalLoggedRegisterProcessOnPublic class="bg-transparent">
              <ButtonMobile icon-class="fas fa-heart text-white" />
            </a>
          </div>

          <div v-else class="btn-group mr-1 ml-1" role="group">
            <div v-if="idea.followedByAuthUser">
              <a v-if="this.module.advancedRegistrationRequiredForFollowing" class="bg-transparent"
                @click="advDecFollower($event)">
                <ButtonMobile icon-class="fas fa-heart text-warning" />
              </a>
              <a v-else class="bg-transparent" @click="decrementFollower($event)">
                <ButtonMobile icon-class="fas fa-heart text-warning" />
              </a>
            </div>
            <div v-else>
              <a v-if="this.module.advancedRegistrationRequiredForFollowing" class="bg-transparent"
                @click="advIncFollower($event)">
                <ButtonMobile icon-class="fas fa-heart text-white" />
              </a>
              <a v-else class="bg-transparent" @click="incrementFollower($event)">
                <ButtonMobile icon-class="fas fa-heart text-white" />
              </a>
            </div>
          </div>
        </template>

        <!-- Gestione pulsante Contatta_il_proponente -->
        <!--
        <div class="btn-group mr-1" role="group">
          <a v-if="is_anonymous_on_public"
             v-b-modal.modalRegisterOnPublic>
            <button type="button" class="btn btn-primary rounded">
              <i class="fas fa-handshake text-white pr-0 pb-1"></i>
            </button>
          </a>
          <a  v-else-if="!is_anonymous_on_public && is_email_pending"
              @click.prevent="alertEmailConfirm"
          >
            <button type="button" class="btn btn-primary rounded">
              <i class="fas fa-handshake text-white pr-0 pb-1"></i>
            </button>
          </a>
          <a  v-else-if="!is_anonymous_on_public && !userHasProcess(this.process.id)"
              v-b-modal.modalLoggedRegisterProcessOnPublic
          >
            <button type="button" class="btn btn-primary rounded">
              <i class="fas fa-handshake text-white pr-0 pb-1"></i>
            </button>
          </a>
          <a v-else
             v-b-modal.modalContactOwner>
            <button type="button" class="btn btn-primary rounded">
              <i class="fas fa-handshake text-white pr-0 pb-1"></i>
            </button>
          </a>
        </div>
        -->

        <!-- Gestione pulsante Contatta_il_proponente -->
        <div v-if="flagMessage" class="btn-group mr-1" role="group">
          <a v-if="is_anonymous_on_public" v-b-modal.modalLoginRegisterProcessOnPublic>
            <ButtonMobile icon-class="fas fa-handshake text-white" />
          </a>
          <a v-else-if="!is_anonymous_on_public && is_email_blank" @click.prevent="alertEmailBlank">
            <ButtonMobile icon-class="fas fa-handshake text-white" />
          </a>
          <a v-else-if="!is_anonymous_on_public && is_email_pending" @click.prevent="alertEmailConfirm">
            <ButtonMobile icon-class="fas fa-handshake text-white" />
          </a>
          <a v-else-if="!is_anonymous_on_public && !userHasProcess(this.process.id)
            " v-b-modal.modalLoggedRegisterProcessOnPublic>
            <ButtonMobile icon-class="fas fa-handshake text-white" />
          </a>
          <a v-else-if="!can_access_module('message')" @click="alertUserModuleAccessBitmaskMessage">     
            <ButtonMobile icon-class="fas fa-handshake text-white" :element-class="isContactOwner ? '' : 'disabled'" />
          </a>
          <a v-else :disabled="isContactOwner ? false : true" v-b-modal.modalContactOwner
            @click="forceRenderContactOwner">     
            <ButtonMobile icon-class="fas fa-handshake text-white" :element-class="isContactOwner ? '' : 'disabled'" />
          </a>
        </div>

        <!--:: Valuta idea -->
        <div class="btn-group mr-1" role="group" v-if="seeEvaluateButton() && this.see_tabs == true"
          @click="scrollMeToEvaluate('tabs')">
          <ButtonMobile icon-class="fas fa-clipboard-check text-white" />
        </div>

        <!--:: Edit idea -->
        <div class="btn-group mr-1" role="group" v-if="canModify() && module.canUpdate && this.see_tabs == true">
          <a class="bg-transparent" @click.prevent="editIdea(idea.id)">
            <ButtonMobile icon-class="fas fa-edit text-white" />
          </a>            
          <!-- </router-link> -->
        </div>

        <!--:: Delete idea -->
        <div class="btn-group mr-1" role="group" v-if="canDelete() && module.canDelete && this.see_tabs == true">
          <!--          todo: controlla che listener funzioni -->
          <a class="bg-transparent" @click="deleteIdea($event)">
            <ButtonMobile icon-class="fas fa-trash-alt text-white text-white" />
          </a>
          <!--          <button type="button" class="btn btn-primary rounded" @click="deleteIdea($event)">-->
          <!--              <i class="fas fa-trash-alt text-white pr-0 pb-1"></i>-->
          <!--            </button>-->
        </div>

        <!-- Social sharing -->
        <div class="btn-group mr-1" role="group">
          <a v-b-modal.modalSocialSharing>
            <ButtonMobile icon-class="fas fa-share-alt text-white" />
          </a>
        </div>

        <!--:: Assistenza -->
        <div class="btn-group mr-1" role="group">
          <a class="bg-transparent" @click.prevent="alertHelp">
            <ButtonMobile icon-class="far fa-life-ring text-white" />
          </a>
          <!--          <button-->
          <!--              type="button"-->
          <!--              class="btn btn-primary rounded"-->
          <!--              @click.prevent="alertHelp">-->
          <!--            <i class="far fa-life-ring text-white pr-0 pb-1"></i>-->
          <!--          </button>-->
        </div>
      </div>

      <!-- Summary -->
      <div class="row">
        <div class="col-lg-12 justify-content-start">
          <!--Versioning-->
          <div class="d-flex flex-column mx-5" v-if="activeVersioning()">
            <div class="row my-2">
              <div class="col-12 text-right">
                <template v-if="this.versions && this.versions.length > 0">
                  <div class="d-inline-block pr-5" style="color: #80808f; font-weight: 400">
                    {{
                      $t(
                        $handleContentTypeLabel(moduleContentType) +
                        "DETAIL.VERSIONIPROPOSTA"
                      )
                    }}
                  </div>
                  <router-link v-for="item in this.versions" :key="item.id" :to="{
                    name: 'processModuleIdeaDetail',
                    params: {
                      id: item.id,
                      process_id: process_id,
                      phase_id: phase_id,
                      module_id: module_id,
                    },
                  }">
                    <button class="btn btn-icon mr-2 font-weight-bolder text-uppercase" v-on:click="tabs_toogle(true)"
                      :class="{
                        'btn-light':
                          item.versionNumber != versionNumber || !see_tabs,
                        'btn-primary':
                          item.versionNumber == versionNumber && see_tabs,
                        'border-danger':
                          item.versionNumber != versionNumber &&
                          item.ideaStatus.id == 1,
                      }">
                      {{ item.versionNumber }}
                    </button>
                  </router-link>
                </template>

                <button class="btn btn-icon font-weight-bolder text-uppercase" v-on:click="tabs_toogle(false)" :class="{
                  'mr-2':
                    canVersioning() || (isAuthor() && this.idIdeaDraft > 0),
                  'btn-light': see_tabs,
                  'btn-primary': !see_tabs,
                }">
                  <i class="fas fa-layer-group"></i>
                </button>

                <span v-if="canVersioning() &&
                  (this.maxVersionsPerContent == 0 ||
                    (this.maxVersionsPerContent > 0 &&
                      this.versions &&
                      this.versions.length < this.maxVersionsPerContent))
                  ">
                  <button class="btn btn-primary font-weight-bolder text-uppercase mt-3 mt-md-0" @click="openVersioning()">
                      {{ $t("IDEADETAIL.NUOVAVERSIONE") }}
                    </button>
                </span>

                <span v-else-if="canVersioning() &&
                  this.versions &&
                  this.versions.length >= this.maxVersionsPerContent
                  ">
                  <button v-on:click="alertCanVersion()"
                    class="btn btn-primary font-weight-bolder text-uppercase mt-3 mt-md-0">
                    {{ $t("IDEADETAIL.NUOVAVERSIONE") }}
                  </button>
                </span>

                <span v-else-if="canModify()">
                  <button @click="editIdea(idIdeaDraft)" class="btn btn-primary font-weight-bolder text-uppercase mt-3 mt-sm-0">
                      {{ $t("IDEADETAIL.COMPLETABOZZA") }}
                  </button>
                </span>
              </div>
            </div>
          </div>

          <!--::Idea Alert Vote -->
          <div class="d-flex flex-column mx-5">
            <div class="alert alert-custom alert-2x alert-success fade show mb-2 py-2" role="alert"
              v-if="idea.type1VotedByAuthUser">
              <div class="alert-icon">
                <i class="icon flaticon2-checkmark icon-md"></i>
              </div>
              <div class="alert-text text-uppercase font-weight-bolder">
                {{
                  $t(
                    $handleContentTypeLabel(moduleContentType) +
                    "DETAIL.IDEAVOTATA"
                  )
                }}
              </div>
            </div>
          </div>

          <!--:: Idea Actions-->
          <div class="d-flex flex-column mx-5" ref="tabs" v-if="this.see_tabs">
            <div class="row">
              <b-tabs class="mx-4 w-100" v-model="tabindex">
                <!--:: Idea Descrizione [0]-->
                <b-tab>
                  <template v-slot:title>
                    <div class="p-3 border-bottom border-2 border-bottom-primary" v-on:click="tabindexString = ''">
                      {{ $t("IDEADETAIL.DESCRIZIONE") }}
                    </div>
                  </template>

                  <div class="card card-custom shadow-sm gutter-b mt-3 ribbon ribbon-clip ribbon-right">
                    <!--Ribbon Versioning-->
                    <div v-if="versionNumber > 0 && activeVersioning()" class="ribbon-target p-3" style="top: 12px">
                      <span class="ribbon-inner bg-primary"></span>
                      <span class="text-uppercase font-weight-bolder h3">{{
                        versionNumber
                      }}</span>
                    </div>
                    <!--end::Ribbon Versioning-->

                    <div class="card-body font-size-h5">
                      <!-- Card Header-->
                      <div class="row align-items-center">
                        <div class="col">
                          <h2 class="font-weight-bolder text-dark float-left">
                            {{ ideaTitle }}
                          </h2>
                          <span v-if="idea.ideaStatus.id == 1"
                            class="badge badge-primary font-weight-bolder text-uppercase ml-3 p-3 text-white bg-danger float-left">
                            {{ $t("IDEADETAIL.INBOZZA") }}
                          </span>
                        </div>
                        <div class="col mx-0 px-0 text-right">
                          <ChangeDataLanguages :localizations="ideaLocalizations" :lang="lang"
                            @changeLanguage="setLanguage($event)"></ChangeDataLanguages>
                        </div>
                      </div>
                      <div class="separator separator-solid separator-black opacity-100 my-2"></div>
                      <!--end::Card Header-->

                      <!--:: Vote MOBILE start -->
                      <div class="row mb-5">
                        <div class="col-12 col-md-6 col-lg-6 col-xl-7 align-items-start">
                          <!--<div v-if="youtubeLink" class="mb-5">
                            <div class="font-weight-bolder font-size-lg" v-html="youtubeLink"></div>
                          </div>-->

                          <div v-if="youtubeLink" class="mb-5">
                            <div class="font-weight-bolder font-size-lg">
                              <iframe style="width: 100%; height: 350px" :src="youtubeLink" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                            </div>
                          </div>

                          <div v-else-if="getLogo" class="mb-5 image-idea">
                            <img :src="getLogo" class="portrait" />
                          </div>
                          <!--                          <h4 class="font-weight-bolder pt-5 border-top mb-5 ">{{$t("IDEADETAIL.DESCRIZIONE")}}</h4>-->
                          <div :class="[
                            'ql-editor reset-height mb-5',
                            this.ideaDescription.length < 600
                              ? 'h4 font-weight-normal'
                              : '',
                          ]" v-html="this.ideaDescription"></div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-5">
                          <!--::Info Idea -->
                          <div class="mb-5" v-if="idea.author.profilePublic">
                            <router-link target="_blank" class="text-hover-primary d-flex align-items-center" :to="{
                              name: 'userDetail',
                              params: { id: idea.author.id },
                            }">
                              <span class="symbol symbol-circle" :style="$imageOptions.styleSettings({
                                width: 40,
                                height: 40,
                                userImage:
                                  idea.author.avatar &&
                                    idea.author.avatar.uri
                                    ? idea.author.avatar.uri
                                    : $imageOptions.defaultAvatarUrl,
                              })
                                "></span>
                              <div class="text-muted font-weight-bold">
                                <div class="ml-3">
                                  <b>{{
                                    $t(
                                      $handleContentTypeLabel(
                                        moduleContentType
                                      ) + "DETAIL.AUTORE"
                                    )
                                  }}</b>
                                  <span v-if="$doNOTShowIfTactic(moduleContentType)">:</span>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning" v-if="idea.author.name == 'admin' ||
                                    idea.author.name == 'facilitator'
                                    ">
                                    <i class="fas fa-crown text-white"></i>
                                  </span>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                    v-else-if="idea.author.name == 'expert' ||
                                      idea.author.name == 'assessor'
                                      ">
                                    <i class="fas fa-pen text-white"></i>
                                  </span>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                    v-else-if="idea.author.name == 'delegate'">
                                    <i class="fas fa-star text-white"></i>
                                  </span>
                                  <span v-else> </span>
                                  <span class="text-dark-75 ml-2">{{ idea.author.name }}
                                    {{ idea.author.surname }}</span>
                                </div>
                                <div v-if="phase.processVotes.length == 0 &&
                                  idea.publicationDate &&
                                  $doNOTShowIfTactic(moduleContentType)
                                  " class="ml-3">
                                  <b>{{ $t("IDEADETAIL.PUBBLICATAIL") }} </b>
                                  <span class="ml-2 text-dark-75">{{
                                    idea.publicationDate | moment("D/MM/YYYY")
                                  }}</span>
                                </div>
                                <div v-else-if="phase.processVotes.length == 0 &&
                                    $doNOTShowIfTactic(moduleContentType)
                                    " class="ml-3">
                                  <b>{{ $t("IDEADETAIL.CREATAIL") }} </b>
                                  <span class="ml-2 text-dark-75">{{
                                    idea.createdDate | moment("D/MM/YYYY")
                                  }}</span>
                                </div>
                              </div>
                            </router-link>
                          </div>

                          <div class="d-flex align-items-center mb-5" v-else>
                            <span class="symbol symbol-circle" :style="$imageOptions.styleSettings({
                                  width: 40,
                                  height: 40,
                                  userImage:
                                    idea.author.avatar && idea.author.avatar.uri
                                      ? idea.author.avatar.uri
                                      : $imageOptions.defaultAvatarUrl,
                                })
                                "></span>
                            <div class="text-muted font-weight-bold">
                              <div class="ml-3">
                                <b>{{
                                  $t(
                                    $handleContentTypeLabel(moduleContentType) +
                                    "DETAIL.AUTORE"
                                  )
                                }}</b>
                                <span v-if="$doNOTShowIfTactic(moduleContentType)">:</span>
                                <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning" v-if="idea.author.name == 'admin' ||
                                  idea.author.name == 'facilitator'
                                  ">
                                  <i class="fas fa-crown text-white"></i>
                                </span>
                                <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                  v-else-if="idea.author.name == 'expert' ||
                                    idea.author.name == 'assessor'
                                    ">
                                  <i class="fas fa-pen text-white"></i>
                                </span>
                                <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                  v-else-if="idea.author.name == 'delegate'">
                                  <i class="fas fa-star text-white"></i>
                                </span>
                                <span v-else> </span>

                                <span class="text-dark-75 ml-2">{{
                                  idea.author.username
                                }}</span>
                              </div>

                              <div v-if="phase.processVotes.length == 0 &&
                                  idea.publicationDate &&
                                  $doNOTShowIfTactic(moduleContentType)
                                  " class="ml-3">
                                <b>{{ $t("IDEADETAIL.PUBBLICATAIL") }}</b>&nbsp;<span class="ml-2 text-dark-75">{{
                                  idea.publicationDate | moment("D/MM/YYYY")
                                }}</span>
                              </div>
                              <div v-else-if="phase.processVotes.length == 0 &&
                                  $doNOTShowIfTactic(moduleContentType)
                                  " class="ml-3">
                                <b>{{ $t("IDEADETAIL.CREATAIL") }}</b>&nbsp;<span class="ml-2 text-dark-75">{{
                                  idea.createdDate | moment("D/MM/YYYY")
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <!--::Info Idea end -->

                          <!--::IDEA RESULT -->
                          <b-list-group-item v-if="$showIfService(moduleContentType)"
                            class="d-flex justify-content-between align-items-center border-bottom">
                            <span class="h5 font-weight-bolder mr-3">{{ $t("IDEADETAIL.SOTTOCATEGORIA") }}:
                            </span>
                            <span v-if="idea.contentSubtype">{{
                              idea.contentSubtype.localizations[indexLanguage]
                                .name
                            }}</span>
                            <span v-else class="align-center"> - </span>
                          </b-list-group-item>

                          <b-list-group-item v-if="idea.url"
                            class="d-flex justify-content-between align-items-center border-bottom">
                            <span class="h5 font-weight-bolder">{{
                              $t(
                                $handleContentTypeLabel(moduleContentType) +
                                "DETAIL.URLSITO"
                              )
                            }}:
                            </span>
                            <a :href="idea.url" target="_blank">{{
                              $t("IDEADETAIL.VAIALSITO")
                            }}</a>
                          </b-list-group-item>

                          <b-list-group v-if="seeAssessMaster()" class="mb-5">
                            <b-list-group-item class="d-flex justify-content-between align-items-center cursor-pointer"
                              :style="'color:#fff; background-color:' +
                                idea.ideaSimpleAssessmentStatus.color
                                " v-on:click="scrollMeToEvaluate('tabs')">
                              <span class="h4 text-white">{{
                                idea.ideaSimpleAssessmentStatus.localizations[
                                  indexLanguage
                                ].name
                              }}</span>
                            </b-list-group-item>
                          </b-list-group>

                          <b-list-group class="mb-3">
                            <!--Budget-->
                            <b-list-group-item v-if="idea.budgetMin"
                              class="d-flex justify-content-between align-items-center border-bottom">
                              <span class="h5 font-weight-bolder">{{ $t("IDEADETAIL.BUDGET") }}
                              </span>
                              <span class="h3">{{ idea.budgetMin }} €</span>
                            </b-list-group-item>

                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                              v-if="publishValueVotes1">
                              <span class="text-muted">{{
                                $t("IDEADETAIL.PREFRACCOLTE")
                              }}</span>
                              <span class="h5 font-weight-bolder">{{
                                idea.valueVotes1
                              }}</span>
                            </b-list-group-item>

                            <b-list-group-item class="d-flex justify-content-between align-items-center"
                              v-if="processVoteIdForVoteType4">
                              <span class="text-muted">{{
                                $t("IDEADETAIL.DONRACCOLTE")
                              }}</span>
                              <span class="h5 font-weight-bolder">€ {{ idea.valueVotes4 }}</span>
                            </b-list-group-item>
                            <!--                            <b-list-group-item-->
                            <!--                                class="d-flex justify-content-between align-items-center"-->
                            <!--                                v-if="phase.phaseStatus.id==3">-->
                            <!--                                <span class="text-muted">{{$t("IDEADETAIL.SOSTENITORI")}}</span>-->
                            <!--                                <span class="h5 font-weight-bolder" v-if="idea.countSupporters"> {{ idea.countSupporters }}</span><span v-else> -</span>-->
                            <!--                            </b-list-group-item>-->
                          </b-list-group>
                          <!--::IDEA RESULT end -->

                          <!--::IDEA VOTE -->
                          <div v-if="canVote() && this.see_tabs == true">
                            <!--::Preferences-->
                            <div class="card card-custom mb-3 shadow-md bg-primary text-white mb-3">
                              <div v-if="moduleVote1IsOpen" class="flex-grow-1 card-spacer-x my-10">
                                <div class="mb-4" v-if="idea.type1VotedByAuthUser">
                                  <span class="h5 mb-5">{{
                                    $t(
                                      $handleContentTypeLabel(
                                        moduleContentType
                                      ) + "DETAIL.GRAZIESCELTAIDEA"
                                    )
                                  }}
                                  </span>
                                  <div class="text-white small mb-3">
                                    {{
                                      $t(
                                        $handleContentTypeLabel(
                                          moduleContentType
                                        ) + "DETAIL.TOGLIEREPREFERENZA"
                                      )
                                    }}
                                  </div>
                                  <div class="text-white small mb-3">
                                    {{ $t("IDEADETAIL.HAIANCORA") }}
                                    {{
                                      idea.leftValueVotes1ForP2MIdeasByAuthUser
                                    }}
                                    {{
                                      $t(
                                        $handleContentTypeLabel(
                                          moduleContentType
                                        ) + "DETAIL.PREFERENZEPERPREMIARE"
                                      )
                                    }}
                                  </div>
                                </div>
                                <div class="mb-4" v-else>
                                  <span class="h5 mb-5">{{
                                    $t(
                                      $handleContentTypeLabel(
                                        moduleContentType
                                      ) + "DETAIL.CLICCAILPULSANTE"
                                    )
                                  }}</span>
                                  <div class="text-white small mb-3">
                                    {{ $t("IDEADETAIL.HAIANCORA") }}
                                    {{
                                      idea.leftValueVotes1ForP2MIdeasByAuthUser
                                    }}
                                    {{
                                      $t(
                                        $handleContentTypeLabel(
                                          moduleContentType
                                        ) + "DETAIL.PREFERENZEPERPREMIARE"
                                      )
                                    }}
                                  </div>
                                </div>
                                <div class="d-flex flex-wrap align-items-center">
                                  <!-- Gestione FAENZA Metti Preferenza -->
                                  <div v-if="this.module
                                        .advancedRegistrationRequiredForVoting
                                      ">
                                    <button v-if="idea.type1VotedByAuthUser" @click="advDelPreferenza"
                                      class="btn btn-lg btn-outline-white border-2 font-weight-bolder text-uppercase rounded-0 border-light">
                                      {{ $t("IDEADETAIL.RIMUOVIPREF") }}
                                    </button>
                                    <button v-else @click="advAddPreferenza"
                                      class="btn btn-lg btn-warning font-weight-bolder text-uppercase border-3 rounded-0 border-light">                                      
                                      <i class="fas fa-star text-white pb-1"></i>
                                      {{ $t("IDEADETAIL.METTIPREF") }}
                                    </button>
                                  </div>

                                  <!-- Gestione Altri Metti Preferenza -->
                                  <div v-else>
                                    <div v-if="is_anonymous_on_public" class="flex-grow-1 text-left">
                                      <div
                                        class="btn btn-lg btn-warning font-weight-bolder text-uppercase border-3 rounded-0 border-light"
                                        v-b-modal.modalLoginRegisterProcessOnPublic id="pop-plus">
                                        <i class="fas fa-star text-white pb-1"></i>
                                        {{ $t("IDEADETAIL.METTIPREF") }}
                                      </div>
                                    </div>
                                    <div v-else-if="!can_access_module('vote')">
                                      <a @click="alertUserModuleAccess()">
                                        <div
                                          class="btn btn-lg btn-warning font-weight-bolder text-uppercase border-3 rounded-0 border-light">
                                          <i class="fas fa-star text-white pb-1"></i>
                                          {{ $t("IDEADETAIL.METTIPREF") }}
                                        </div>
                                      </a>
                                    </div>
                                    <div v-else-if="!is_anonymous_on_public &&
                                      is_mobile_pending &&
                                      is_email_pending
                                      " class="flex-grow-1 text-left">
                                      <a @click.prevent="alertEmailMobileConfirm">
                                        <div
                                          class="btn btn-lg btn-warning font-weight-bolder text-uppercase border-3 rounded-0 border-light">
                                          <i class="fas fa-star text-white pb-1"></i>
                                          {{ $t("IDEADETAIL.METTIPREF") }}
                                        </div>
                                      </a>
                                    </div>
                                    <div v-else-if="!is_anonymous_on_public &&
                                      is_mobile_pending &&
                                      !is_email_confirmed
                                      " class="flex-grow-1 text-left">
                                      <a @click.prevent="alertMobileConfirm">
                                        <div
                                          class="btn btn-lg btn-warning font-weight-bolder text-uppercase border-3 rounded-0 border-light">
                                          <i class="fas fa-star text-white pb-1"></i>
                                          {{ $t("IDEADETAIL.METTIPREF") }}
                                        </div>
                                      </a>
                                    </div>
                                    <div v-else-if="!is_anonymous_on_public &&
                                      is_email_pending &&
                                      !is_mobile_confirmed
                                      " class="flex-grow-1 text-left">
                                      <a @click.prevent="alertEmailConfirm">
                                        <div
                                          class="btn btn-lg btn-warning font-weight-bolder text-uppercase border-3 rounded-0 border-light">
                                          <i class="fas fa-star text-white pb-1"></i>
                                          {{ $t("IDEADETAIL.METTIPREF") }}
                                        </div>
                                      </a>
                                    </div>
                                    <div v-else-if="!is_anonymous_on_public &&
                                      !userHasProcess(this.process.id)
                                      " class="flex-grow-1 text-left">
                                      <div
                                        class="btn btn-lg btn-warning font-weight-bolder text-uppercase border-3 rounded-0 border-light"
                                        v-b-modal.modalLoggedRegisterProcessOnPublic id="pop-plus">
                                        <i class="fas fa-star text-white pb-1"></i>
                                        {{ $t("IDEADETAIL.METTIPREF") }}
                                      </div>
                                    </div>
                                    <div v-else class="flex-grow-1 text-left">
                                      <div v-if="idea.type1VotedByAuthUser" @click="simpleDelPreferenza"
                                        class="btn btn-lg btn-outline-white border-2 font-weight-bolder text-uppercase rounded-0 border-light">
                                        {{ $t("IDEADETAIL.RIMUOVIPREF") }}
                                      </div>
                                      <div v-else @click="simpleAddPreferenza"
                                        class="btn btn-lg btn-warning font-weight-bolder text-uppercase border-3 rounded-0 border-light">
                                        <i class="fas fa-star text-white pb-1"></i>
                                        {{ $t("IDEADETAIL.METTIPREF") }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--::Crowdfunding-->
                            <div class="card card-custom shadow-md mb-3">
                              <div v-if="moduleVote4IsOpen" class="flex-grow-1 card-spacer-x my-5">
                                <div class="row mt-5">
                                  <div class="col-12 align-items-center">
                                    <div class="mb-2" v-if="idea.type4VotedByAuthUser > 0">
                                      <span class="h5 font-size-bolder mb-3">{{
                                        $t(
                                          $handleContentTypeLabel(
                                            moduleContentType
                                          ) + "DETAIL.GRAZIESOSTEGNO"
                                        )
                                      }}</span>
                                      <div class="text-dark-75 small mb-3">
                                        {{
                                          $t(
                                            $handleContentTypeLabel(
                                              moduleContentType
                                            ) + "DETAIL.SOSTIENIALTREIDEE"
                                          )
                                        }}
                                        <!-- {{$t("IDEADETAIL.RICORDATI3")}}<br />
                                          {{$t("IDEADETAIL.HAIANCORA2")}} <b>{{idea.maxBudgetPerUser}} €</b> -->
                                      </div>
                                    </div>
                                    <div class="my-2" v-else>
                                      <span class="h5 font-size-bolder mb-8">
                                        {{
                                          $t(
                                            $handleContentTypeLabel(
                                              moduleContentType
                                            ) + "DETAIL.WALLETSOSTIENIIDEA"
                                          )
                                        }}</span>
                                      <div class="text-dark-75 small mb-5">
                                        {{
                                          $t(
                                            $handleContentTypeLabel(
                                              moduleContentType
                                            ) + "DETAIL.INSERISCICIFRA"
                                          )
                                        }}
                                        <br />
                                        <span v-if="this.voteParams.borders.min ||
                                            this.voteParams.borders.max
                                            ">
                                          {{ $t("IDEADETAIL.PUOIDONARE") }}
                                          <span v-if="this.voteParams.borders.min">
                                            {{ $t("IDEADETAIL.DONAREDA") }}
                                            <b>{{
                                              this.voteParams.borders.min
                                            }}
                                              €</b>
                                          </span>
                                          <span v-if="this.voteParams.borders.max">
                                            {{ $t("IDEADETAIL.DONAREFINOA") }}
                                            <b>{{
                                              this.voteParams.borders.max
                                            }}
                                              €</b>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-12 align-items-center">
                                    <div class="form-group mb-3">
                                      <div :class="[
                                            'input-group input-group-solid border-0',
                                            hasForbiddenCharacters
                                              ? 'invalid'
                                              : '',
                                          ]">
                                        <div class="input-group-prepend">
                                          <span class="input-group-text h3 font-weight-bolder">€</span>
                                        </div>
                                        <input type="number" class="form-control font-size-h4 font-size-bolder"
                                          placeholder="" v-model="voto4" min="0" @wheel="(e) => e.target.blur()"
                                          @keyup="getDonationValue" />
                                        <div class="input-group-append border-0">
                                          <!-- Gestione CF Faenza con adv autentication -->
                                          <div v-if="this.module
                                              .advancedRegistrationRequiredForVoting
                                            ">
                                            <button @click="advAddWallet"
                                              class="btn btn-warning text-uppercase font-weight-bolder py-4 border-0 border-light"
                                              type="button">
                                              {{ $t("IDEADETAIL.DONA") }}
                                            </button>
                                          </div>
                                          <!-- Gestione CF NON Faenza con simple autentication -->
                                          <div v-else>
                                            <div v-if="is_anonymous_on_public">
                                              <div class="col-12 align-items-center"
                                                v-b-modal.modalLoginRegisterProcessOnPublic id="pop-plus">
                                                <button
                                                  class="btn btn-warning text-uppercase font-weight-bolder py-4 border-0 border-light"
                                                  type="button">
                                                  {{ $t("IDEADETAIL.DONA") }}
                                                </button>
                                              </div>
                                            </div>
                                            <div v-else-if="!is_anonymous_on_public &&
                                              is_mobile_pending &&
                                              is_email_pending
                                              " class="col-12 align-items-center">
                                              <button @click.prevent="alertEmailMobileConfirm
                                                "
                                                class="btn btn-warning text-uppercase font-weight-bolder py-4 border-0 border-light"
                                                type="button">
                                                {{ $t("IDEADETAIL.DONA") }}
                                              </button>
                                            </div>
                                            <div v-else-if="!is_anonymous_on_public &&
                                              is_mobile_pending &&
                                              !is_email_confirmed
                                              " class="col-12 align-items-center">
                                              <button @click.prevent="alertMobileConfirm
                                                "
                                                class="btn btn-warning text-uppercase font-weight-bolder py-4 border-0 border-light"
                                                type="button">
                                                {{ $t("IDEADETAIL.DONA") }}
                                              </button>
                                            </div>
                                            <div v-else-if="!is_anonymous_on_public &&
                                              is_email_pending &&
                                              !is_mobile_confirmed
                                              " class="col-12 align-items-center">
                                              <button @click.prevent="alertEmailConfirm
                                                "
                                                class="btn btn-warning text-uppercase font-weight-bolder py-4 border-0 border-light"
                                                type="button">
                                                {{ $t("IDEADETAIL.DONA") }}
                                              </button>
                                            </div>
                                            <div v-else-if="!is_anonymous_on_public &&
                                              !userHasProcess(this.process.id)
                                              ">
                                              <div class="col-12 align-items-center"
                                                v-b-modal.modalLoggedRegisterProcessOnPublic id="pop-plus">
                                                <button
                                                  class="btn btn-warning text-uppercase font-weight-bolder py-4 border-0 border-light"
                                                  type="button">
                                                  {{ $t("IDEADETAIL.DONA") }}
                                                </button>
                                              </div>
                                            </div>
                                            <div v-else class="col-12 align-items-center">
                                              <!-- Caso utente autenticato -->
                                              <button @click="simpleAddWallet"
                                                class="btn btn-warning text-uppercase font-weight-bolder py-4 border-0 border-light"
                                                type="button">
                                                {{ $t("IDEADETAIL.DONA") }}
                                              </button>
                                              <!-- Fine caso utente autenticato -->
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <b-list-group class="mb-3">
                                  <b-list-group-item
                                    class="d-flex justify-content-between align-items-center border-bottom">
                                    <span class="h5 font-weight-bolder">{{
                                      $t("IDEADETAIL.FONDIRACCOLTI")
                                    }}</span>
                                    <span class="h3">{{
                                      idea.valueVotes4
                                      ? idea.valueVotes4
                                      : "0"
                                    }}
                                      €</span>
                                  </b-list-group-item>
                                  <b-list-group-item
                                    class="d-flex justify-content-between align-items-center border-bottom">
                                    <span class="h5 font-weight-bolder">{{
                                      $t("IDEADETAIL.COSTOIDEA")
                                    }}</span>
                                    <span class="h3">{{
                                      idea.budgetMin
                                      ? idea.budgetMin + " €"
                                      : "-&#45;&#45;"
                                    }}</span>
                                  </b-list-group-item>
                                  <b-list-group-item
                                    class="d-flex justify-content-between align-items-center border-bottom">
                                    <span class="h5 font-weight-bolder">{{
                                      $t("IDEADETAIL.SOSTENITORI")
                                    }}</span>
                                    <span class="h3">{{
                                      idea.countSupporters
                                    }}</span>
                                  </b-list-group-item>
                                </b-list-group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <!--:: Idea Storytelling [1] -->
                <b-tab v-if="ideaNarration !== ''">
                  <template v-slot:title>
                    <div class="p-3 border-bottom border-2 border-bottom-primary" v-on:click="tabindexString = ''">
                      {{
                        $t(
                          $handleContentTypeLabel(moduleContentType) +
                          "DETAIL.NARRAZIONE"
                        )
                      }}
                    </div>
                  </template>

                  <div class="card card-custom shadow-sm gutter-b mt-3 ribbon ribbon-clip ribbon-right">
                    <!--Ribbon Versioning-->
                    <div v-if="versionNumber > 0 && activeVersioning()" class="ribbon-target p-3" style="top: 12px">
                      <span class="ribbon-inner bg-primary"></span>
                      <span class="text-uppercase font-weight-bolder h3">{{
                        versionNumber
                      }}</span>
                    </div>
                    <!--end::Ribbon Versioning-->

                    <div class="card-body font-size-h5">
                      <!-- Card Header-->
                      <div class="row align-items-center">
                        <div class="col">
                          <h2 class="font-weight-bolder text-dark float-left">
                            {{ ideaTitle }}
                          </h2>
                          <span v-if="idea.ideaStatus.id == 1"
                            class="badge badge-primary font-weight-bolder text-uppercase ml-3 p-3 text-white bg-danger float-left">
                            {{ $t("IDEADETAIL.INBOZZA") }}
                          </span>
                        </div>
                        <div class="col mx-0 px-0 text-right">
                          <ChangeDataLanguages :localizations="ideaLocalizations" :lang="lang"
                            @changeLanguage="setLanguage($event)"></ChangeDataLanguages>
                        </div>
                      </div>
                      <div class="separator separator-solid separator-black opacity-100 my-2"></div>
                      <!--end::Card Header-->
                      <div class="row col-12 p-5 ql-editor reset-height">
                        <p v-html="ideaNarration"></p>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <!--:: Idea Allegati [2] -->
                <b-tab v-if="this.idea.resources && this.idea.resources.length > 0">
                  <template v-slot:title>
                    <div class="p-3 border-bottom border-2 border-bottom-primary" v-on:click="tabindexString = ''">
                      {{ $t("IDEADETAIL.APPROFONDISCI") }}
                    </div>
                  </template>

                  <div class="card card-custom shadow-sm gutter-b mt-3 ribbon ribbon-clip ribbon-right">
                    <!--Ribbon Versioning-->
                    <div v-if="versionNumber > 0 && activeVersioning()" class="ribbon-target p-3" style="top: 12px">
                      <span class="ribbon-inner bg-primary"></span>
                      <span class="text-uppercase font-weight-bolder h3">{{
                        versionNumber
                      }}</span>
                    </div>
                    <!--end::Ribbon Versioning-->

                    <div class="card-body font-size-h5">
                      <!-- Card Header-->
                      <div class="row align-items-center">
                        <div class="col">
                          <h2 class="font-weight-bolder text-dark float-left">
                            {{ ideaTitle }}
                          </h2>
                          <span v-if="idea.ideaStatus.id == 1"
                            class="badge badge-primary font-weight-bolder text-uppercase ml-3 p-3 text-white bg-danger float-left">
                            {{ $t("IDEADETAIL.INBOZZA") }}
                          </span>
                        </div>
                        <div class="col mx-0 px-0 text-right">
                          <!--<ChangeDataLanguages :localizations="ideaLocalizations" :lang="lang" @changeLanguage="setLanguage($event)"></ChangeDataLanguages>-->
                        </div>
                      </div>
                      <div class="separator separator-solid separator-black opacity-100 my-2"></div>
                      <!--end::Card Header-->

                      <div class="row" v-if="this.images.length > 0">
                        <div v-if="selectedImage" class="w-100 text-center">
                          <img :src="selectedImage" alt="" style="max-width: 100%" @click.stop="selectedImage = null" />
                          <hr />
                        </div>

                        <div class="col-6 col-xl-3 bg-white rounded-xl" v-for="image in this.images" :key="image.id">
                          <div class="card card-custom gutter-b card-stretch text-center">
                            <div class="card-body">
                              <a :href="image.uri" target="_blank">
                                <span class="symbol-label">
                                  <img :src="image.uri" class="w-100 h-100 align-self-center" alt="gallery"
                                    @click="imageZoom(image.uri, $event)" />
                                </span>
                              </a>
                              <a :href="image.uri" class="btn btn-primary" download>Download</a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <b-list-group v-if="this.attaches.length > 0">
                        <b-list-group-item class="d-flex justify-content-between align-items-center"
                          v-for="item in this.attaches" :key="item.id">
                          <a :href="item.uri" target="_blank">{{
                            item.name
                          }}</a>
                          <b-badge variant="primary" pill>PDF</b-badge>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </div>
                </b-tab>

                <!--:: Idea Challenge [3] -->
                <b-tab v-if="this.idea.countChallenges && this.idea.countChallenges > 0
                  ">
                  <template v-slot:title>
                    <div class="p-3 border-bottom border-2 border-bottom-primary" v-on:click="tabindexString = ''">
                      {{ $t("IDEADETAIL.CHALLENGE") }}
                      <span class="label label-light-primary mr-2" style="margin-bottom: -3px">{{ challengesNumber
                      }}</span>
                    </div>
                  </template>

                  <div class="card card-custom shadow-sm gutter-b mt-3 ribbon ribbon-clip ribbon-right">
                    <!--Ribbon Versioning-->
                    <div v-if="versionNumber > 0 && activeVersioning()" class="ribbon-target p-3" style="top: 12px">
                      <span class="ribbon-inner bg-primary"></span>
                      <span class="text-uppercase font-weight-bolder h3">{{
                        versionNumber
                      }}</span>
                    </div>
                    <!--end::Ribbon Versioning-->

                    <div class="card-body font-size-h5">
                      <!-- Card Header-->
                      <div class="row align-items-center">
                        <div class="col">
                          <h2 class="font-weight-bolder text-dark float-left">
                            {{ ideaTitle }}
                          </h2>
                          <span v-if="idea.ideaStatus.id == 1"
                            class="badge badge-primary font-weight-bolder text-uppercase ml-3 p-3 text-white bg-danger float-left">
                            {{ $t("IDEADETAIL.INBOZZA") }}
                          </span>
                        </div>
                        <div class="col mx-0 px-0 text-right">
                          <!--<ChangeDataLanguages :localizations="ideaLocalizations" :lang="lang" @changeLanguage="setLanguage($event)"></ChangeDataLanguages>-->
                        </div>
                      </div>
                      <div class="separator separator-solid separator-black opacity-100 my-2"></div>
                      <!--end::Card Header-->

                      <div class="row" v-if="idea.challenges.length > 0">
                        <div class="col-12 col-lg-6 bg-white rounded-xl" v-for="a in this.idea.challenges" :key="a.id">
                          <label class="option ribbon ribbon-clip ribbon-right" style="min-height: 150px">
                            <!--Ribbon Agenda-->
                            <div class="ribbon-target w-50" style="
                                bottom: 20px;
                                right: -10px !important;
                                left: none !important;
                              ">
                              <span class="ribbon-inner bg-primary"></span>
                              <span class="text-uppercase font-weight-bolder" style="font-size: 0.8rem !important">{{
                                getAgendaName(a.agendaId) }}</span>
                            </div>
                            <!--end::Ribbon Agenda-->

                            <!--Image-->
                            <span class="option-head">
                              <span class="option-title">
                                <img :src="a.icon && a.icon.uri
                                    ? a.icon.uri
                                    : $imageOptions.defaultLogoUrl
                                  " class="align-self-center" style="
                                    height: 100px;
                                    width: auto;
                                    max-width: 150px;
                                    border-radius: 0.42rem;
                                    object-fit: contain !important;
                                  " />
                              </span>
                            </span>

                            <!--Title-->
                            <span class="option-label ml-3">
                              <span class="option-head">
                                <span class="option-title w-100">
                                  <span class="option-focus w-100">{{
                                    a.localizations != null
                                    ? a.localizations.find(
                                      (x) => x.locale == defaultlang
                                    ).name
                                    : a.name
                                  }}</span>
                                </span>
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <!--:: Idea Scenari [4] -->
                <b-tab v-if="(this.idea.countScenarios &&
                    this.idea.countScenarios > 0) ||
                  displayViewMap
                  ">
                  <template v-slot:title>
                    <div class="p-3 border-bottom border-2 border-bottom-primary" v-on:click="tabindexString = ''">
                      {{ $t("IDEADETAIL.MAPPE") }}
                      <span class="label label-light-primary mr-2" style="margin-bottom: -3px">{{ scenariosNumber
                      }}</span>
                    </div>
                  </template>

                  <div class="card card-custom shadow-sm gutter-b mt-3 ribbon ribbon-clip ribbon-right">
                    <!--Ribbon Versioning-->
                    <div v-if="versionNumber > 0 && activeVersioning()" class="ribbon-target p-3" style="top: 12px">
                      <span class="ribbon-inner bg-primary"></span>
                      <span class="text-uppercase font-weight-bolder h3">{{
                        versionNumber
                      }}</span>
                    </div>
                    <!--end::Ribbon Versioning-->

                    <div class="card-body font-size-h5">
                      <!-- Card Header-->
                      <div class="row align-items-center">
                        <div class="col">
                          <h2 class="font-weight-bolder text-dark float-left">
                            {{ ideaTitle }}
                          </h2>
                          <span v-if="idea.ideaStatus.id == 1"
                            class="badge badge-primary font-weight-bolder text-uppercase ml-3 p-3 text-white bg-danger float-left">
                            {{ $t("IDEADETAIL.INBOZZA") }}
                          </span>
                        </div>
                        <div class="col mx-0 px-0 text-right">
                          <!--<ChangeDataLanguages :localizations="ideaLocalizations" :lang="lang" @changeLanguage="setLanguage($event)"></ChangeDataLanguages>-->
                        </div>
                      </div>
                      <div class="separator separator-solid separator-black opacity-100 my-2"></div>
                      <!--end::Card Header-->

                      <div class="row" v-if="idea.scenarios.length > 0">
                        <div class="col-12 col-lg-6 bg-white rounded-xl" v-for="a in this.idea.scenarios" :key="a.id">
                          <label class="option" style="min-height: 120px">
                            <!--Image-->
                            <span class="option-head">
                              <span class="option-title">
                                <img :src="a.background
                                    ? a.background.uri
                                    : '/assets/media/bipart/avatar-default.svg'
                                  " class="align-self-center" style="
                                    height: 100px;
                                    width: auto;
                                    max-width: 150px;
                                    border-radius: 0.42rem;
                                    object-fit: contain !important;
                                  " />
                              </span>
                            </span>

                            <!--Title-->
                            <span class="option-label ml-3">
                              <span class="option-head">
                                <span class="option-title w-100">
                                  <span class="option-focus w-100">{{
                                    a.localizations != null
                                    ? a.localizations.find(
                                      (x) => x.locale == defaultlang
                                    ).title
                                    : a.title
                                  }}</span>
                                </span>
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>

                      <div class="row" v-if="displayViewMap">
                        <div class="col-12">
                          <Gmap-Map :center="myCoordinates" :zoom="zoom" ref="mapRef" :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: false,
                          }" @dragend="handleDrag" style="width: 100%; height: 500px">
                            <gmap-marker v-if="idea.centroid" :position="marker.position"></gmap-marker>
                          </Gmap-Map>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <!--:: Idea Followers [5] -->
                <b-tab :active="tabindexString == 'followers'" v-if="this.idea &&
                  this.idea.ideaStatus.id == 3 &&
                  $activeFollowing
                  ">
                  <template v-slot:title>
                    <div class="p-3 border-bottom border-2 border-bottom-primary" v-on:click="tabindexString = ''">
                      {{ $t("IDEADETAIL.FOLLOWERS") }}
                      <span class="label label-light-primary mr-2" v-html="idea.countFollowers"
                        style="margin-bottom: -3px"></span>
                    </div>
                  </template>

                  <div class="card card-custom shadow-sm gutter-b mt-3 ribbon ribbon-clip ribbon-right">
                    <!--Ribbon Versioning-->
                    <div v-if="versionNumber > 0 && activeVersioning()" class="ribbon-target p-3" style="top: 12px">
                      <span class="ribbon-inner bg-primary"></span>
                      <span class="text-uppercase font-weight-bolder h3">{{
                        versionNumber
                      }}</span>
                    </div>
                    <!--end::Ribbon Versioning-->

                    <div class="card-body font-size-h5">
                      <!-- Card Header-->
                      <div class="row align-items-center">
                        <div class="col">
                          <h2 class="font-weight-bolder text-dark float-left">
                            {{ ideaTitle }}
                          </h2>
                          <span v-if="idea.ideaStatus.id == 1"
                            class="badge badge-primary font-weight-bolder text-uppercase ml-3 p-3 text-white bg-danger float-left">
                            {{ $t("IDEADETAIL.INBOZZA") }}
                          </span>
                        </div>
                        <div class="col mx-0 px-0 text-right">
                          <!--<ChangeDataLanguages :localizations="ideaLocalizations" :lang="lang" @changeLanguage="setLanguage($event)"></ChangeDataLanguages>-->
                        </div>
                      </div>
                      <div class="separator separator-solid separator-black opacity-100 my-2"></div>
                      <!--end::Card Header-->

                      <div v-if="idea2FollowersCounter > 0" class="row">
                        <div class="col-12 col-md-6 col-lg-3 col-xl-4 bg-white rounded-xl"
                          v-for="a in idea.idea2Followers" :key="a.id">
                          <div class="card card-custom gutter-b card-stretch">
                            <div class="card-body p-5">
                              <div class="d-flex flex-row align-items-center py-1 flex-wrap">
                                <div class="symbol symbol-circle symbol-40 mr-5" :style="$imageOptions.styleSettings({
                                  width: 40,
                                  height: 40,
                                  userImage:
                                    a.follower.avatar &&
                                      a.follower.avatar.uri
                                      ? a.follower.avatar.uri
                                      : $imageOptions.defaultAvatarUrl,
                                })
                                  "></div>

                                <span class="text-dark font-weight-bolder text-hover-primary p"
                                  v-if="a.follower.profilePublic">
                                  <router-link target="_blank"
                                    class="text-dark-75 text-hover-primary font-weight-bold font-size-h6" :to="{
                                      name: 'userDetail',
                                      params: { id: a.follower.id },
                                    }">
                                    <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning" v-if="a.follower.name == 'admin' ||
                                      a.follower.name == 'facilitator'
                                      ">
                                      <i class="fas fa-crown text-white"></i>
                                    </span>
                                    <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                      v-else-if="a.follower.name == 'expert' ||
                                        a.follower.name == 'assessor'
                                        ">
                                      <i class="fas fa-pen text-white"></i>
                                    </span>
                                    <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                      v-else-if="a.follower.name == 'delegate'">
                                      <i class="fas fa-star text-white"></i>
                                    </span>
                                    <span v-else> </span>
                                    {{ a.follower.name }}
                                    {{ a.follower.surname }}
                                  </router-link>
                                </span>
                                <span class="text-dark font-weight-bolder text-hover-primary p" v-else>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning" v-if="a.follower.name == 'admin' ||
                                    a.follower.name == 'facilitator'
                                    ">
                                    <i class="fas fa-crown text-white"></i>
                                  </span>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                    v-else-if="a.follower.name == 'expert' ||
                                      a.follower.name == 'assessor'
                                      ">
                                    <i class="fas fa-pen text-white"></i>
                                  </span>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                    v-else-if="a.follower.name == 'delegate'">
                                    <i class="fas fa-star text-white"></i>
                                  </span>
                                  <span v-else> </span>
                                  <span class="text-dark-75 text-hover-primary font-weight-bold font-size-h6">
                                    {{ a.follower.username }}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="messages" v-else>
                        <h4 class="font-bolder">
                          {{ $t("IDEADETAIL.NOCOMMUNITY") }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <!--:: Idea Comment [6]-->
                <b-tab :active="tabindexString == 'comments'" v-if="this.idea &&
                  this.idea.ideaStatus.id == 3 &&
                  this.activeCommenting
                  ">
                  <template v-slot:title>
                    <div class="p-3 border-bottom border-2 border-bottom-primary"
                      v-on:click="tabindexString = 'comments'">
                      {{ $t("IDEADETAIL.COMMENTI") }}
                      <span class="label label-light-primary mr-2" style="margin-bottom: -3px">{{ commentNumber }}</span>
                    </div>
                  </template>

                  <div class="card card-custom shadow-sm gutter-b mt-3 ribbon ribbon-clip ribbon-right">
                    <!--Ribbon Versioning-->
                    <div v-if="versionNumber > 0 && activeVersioning()" class="ribbon-target p-3" style="top: 12px">
                      <span class="ribbon-inner bg-primary"></span>
                      <span class="text-uppercase font-weight-bolder h3">{{
                        versionNumber
                      }}</span>
                    </div>
                    <!--end::Ribbon Versioning-->

                    <div class="card-body font-size-h5">
                      <!-- Card Header-->
                      <div class="row align-items-center">
                        <div class="col">
                          <h2 class="font-weight-bolder text-dark float-left">
                            {{ ideaTitle }}
                          </h2>
                          <span v-if="idea.ideaStatus.id == 1"
                            class="badge badge-primary font-weight-bolder text-uppercase ml-3 p-3 text-white bg-danger float-left">
                            {{ $t("IDEADETAIL.INBOZZA") }}
                          </span>
                        </div>
                        <div class="col mx-0 px-0 text-right">
                          <!--<ChangeDataLanguages :localizations="ideaLocalizations" :lang="lang" @changeLanguage="setLanguage($event)"></ChangeDataLanguages>-->
                        </div>
                      </div>
                      <div class="separator separator-solid separator-black opacity-100 my-2"></div>
                      <!--end::Card Header-->

                      <div v-if="canComment() && this.see_tabs == true" class="mb-5">
                        <div v-if="is_anonymous_on_public">
                          {{ $t("IDEADETAIL.COMMENTICONTRIBUISCI") }}:
                          <b-button v-b-modal.modalLoginRegisterProcessOnPublic class="btn btn-primary">
                            {{ $t("IDEADETAIL.COMMENTIENTRA") }}</b-button>
                          o
                          <b-button v-b-modal.modalRegisterOnPublic class="btn btn-primary">
                            {{ $t("IDEADETAIL.COMMENTIREGISTRATI") }}</b-button>
                        </div>
                        <div v-else-if="!is_anonymous_on_public &&
                          is_mobile_pending &&
                          is_email_pending
                          ">
                          <div class="text-center" v-html="$t('IDEADETAIL.COMMENTIALERTEMAILMOBILECONFIRM')
                            "></div>
                        </div>
                        <div v-else-if="!is_anonymous_on_public &&
                          is_mobile_pending &&
                          !is_email_confirmed
                          ">
                          <div class="text-center" v-html="$t('IDEADETAIL.COMMENTIALERTMOBILECONFIRM')"></div>
                        </div>
                        <div v-else-if="!is_anonymous_on_public &&
                          is_email_pending &&
                          !is_mobile_confirmed
                          ">
                          <div class="text-center" v-html="$t('IDEADETAIL.COMMENTIALERTEMAILCONFIRM')"></div>
                        </div>
                        <div v-else-if="!is_anonymous_on_public &&
                          !userHasProcess(this.process.id)
                          ">
                          {{
                            $t(
                              "IDEADETAIL.COMMENTIREGISTRATIALPROCESSOCONTRIBUISCI"
                            )
                          }}:
                          <b-button v-b-modal.modalLoggedRegisterProcessOnPublic class="btn btn-primary">
                            {{ $t("IDEADETAIL.COMMENTIREGISTRATI") }}</b-button>
                        </div>
                        <div v-else>
                          <form>
                            <vue-editor id="input-comment" :editor-toolbar="customToolbar" placeholder=""
                              :tag="'textarea'" v-model="new_comment" class="bg-light-primary" row="10">
                            </vue-editor>
                            <div class="d-flex align-items-center justify-content-between mt-5">
                              <div class="mr-3">
                                {{ $t("IDEADETAIL.INSERISCICOMMENTO") }}
                              </div>
                              <button type="button" v-on:click="submitComment"
                                class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6">
                                {{ $t("IDEADETAIL.INVIA") }}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div v-else>
                        <h4 class="font-bolder pb-5">
                          {{ $t("IDEADETAIL.COMMENTIDISATTIVATI") }}
                        </h4>
                      </div>

                      <div class="scroll scroll-pull" data-mobile-height="350">
                        <!--begin::Messages-->
                        <div id="comments-container" class="messages" v-if="this.commentNumber > 0">
                          <div v-for="(item, index) in idea.ideaComments" :key="item.id">
                            <div class="d-flex flex-column mb-5 align-items-start" :class="[
                              index % 2 === 1
                                ? 'align-items-end'
                                : 'align-items-start',
                            ]">
                              <div class="">
                                <div v-if="item.commenter.profilePublic == true" class="d-flex align-items-center">
                                  <!--                                  d-flex text-dark-75 text-hover-primary font-weight-bold font-size-h6 align-items-center-->
                                  <a :href="`/user-detail/${item.commenter.id}/8`" class="" target="_blank">
                                    <div class="symbol symbol-circle symbol-40 mr-3" :style="$imageOptions.styleSettings({
                                      width: 40,
                                      height: 40,
                                      userImage:
                                        item.commenter.avatar &&
                                          item.commenter.avatar.uri
                                          ? item.commenter.avatar.uri
                                          : $imageOptions.defaultAvatarUrl,
                                    })
                                      "></div>
                                  </a>
                                  <span class="font-weight-bolder text-dark-75 font-size-h6">{{
                                    item.commenter.name ||
                                    item.commenter.surname
                                    ? item.commenter.name +
                                    " " +
                                    item.commenter.surname
                                    : item.commenter.username
                                  }}</span>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning" v-if="item.commenter.name == 'admin' ||
                                      item.commenter.name == 'facilitator'
                                      ">
                                    <i class="fas fa-crown text-white"></i>
                                  </span>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                    v-else-if="item.commenter.name == 'expert' ||
                                      item.commenter.name == 'assessor'
                                      ">
                                    <i class="fas fa-pen text-white"></i>
                                  </span>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                    v-else-if="item.commenter.name == 'delegate'
                                      ">
                                    <i class="fas fa-star text-white"></i>
                                  </span>
                                  <span v-else></span>
                                  <span class="text-muted ml-3">
                                    il
                                    {{
                                      item.createdDate | moment("DD/MM/YYYY")
                                    }}
                                    {{ $t("IDEADETAIL.HASCRITTO") }}</span>
                                </div>
                                <div v-else class="d-flex align-items-center">
                                  <div class="symbol symbol-circle symbol-40 mr-3" :style="$imageOptions.styleSettings({
                                    width: 40,
                                    height: 40,
                                    userImage:
                                      item.commenter.avatar &&
                                        item.commenter.avatar.uri
                                        ? item.commenter.avatar.uri
                                        : $imageOptions.defaultAvatarUrl,
                                  })
                                    "></div>
                                  <span class="font-weight-bolder text-dark-75 font-size-h6">{{ item.commenter.username
                                  }}</span>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning" v-if="item.commenter.name == 'admin' ||
                                    item.commenter.name == 'facilitator'
                                    ">
                                    <i class="fas fa-crown text-white"></i>
                                  </span>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                    v-else-if="item.commenter.name == 'expert' ||
                                      item.commenter.name == 'assessor'
                                      ">
                                    <i class="fas fa-pen text-white"></i>
                                  </span>
                                  <span class="ml-2 label label-lg font-weight-bolder label-rounded label-warning"
                                    v-else-if="item.commenter.name == 'delegate'
                                      ">
                                    <i class="fas fa-star text-white"></i>
                                  </span>
                                  <span v-else></span>
                                  <span class="text-muted ml-3">il
                                    {{
                                      item.createdDate | moment("DD/MM/YYYY")
                                    }}
                                    {{ $t("IDEADETAIL.HASCRITTO") }}</span>
                                </div>
                              </div>
                              <!--
                              :class="[(index % 2 === 1) ? 'text-right' : 'text-left']"
                              -->
                              <div
                                class="ql-editor reset-height mt-1 rounded p-3 bg-light-primary text-dark-50 font-weight-bold font-size-lg w-100"
                                v-html="item.comment"></div>
                              <div class="mr-3" v-if="item.commenter.id === currentUser.id &&
                                canComment()
                                ">
                                <a href="#" class="btn btn-clean btn-icon btn-md" @click="deleteComment(item.id)"
                                  @click.prevent="">
                                  <i class="fas fa-trash-alt"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="messages border-top pt-5" v-else>
                          <h4 class="font-bolder">
                            {{ $t("IDEADETAIL.NOCOMMENTI") }}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <!--:: Idea Evaluation [7] -->
                <b-tab :active="tabindexString == 'evaluations'" v-if="seeEvaluateTab() && this.see_tabs == true">
                  <template v-slot:title>
                    <div class="p-3 border-bottom border-2 border-bottom-primary"
                      v-on:click="tabindexString = 'evaluations'">
                      {{ $t("IDEADETAIL.VALUTAZIONI") }}
                      <span class="label label-light-primary mr-2" style="margin-bottom: -3px">{{ ideaAssessmentNumber
                      }}</span>
                    </div>
                  </template>

                  <div class="card card-custom shadow-sm gutter-b mt-3 ribbon ribbon-clip ribbon-right">
                    <!--Ribbon Versioning-->
                    <div v-if="versionNumber > 0 && activeVersioning()" class="ribbon-target p-3" style="top: 12px">
                      <span class="ribbon-inner bg-primary"></span>
                      <span class="text-uppercase font-weight-bolder h3">{{
                        versionNumber
                      }}</span>
                    </div>
                    <!--end::Ribbon Versioning-->

                    <div class="card-body font-size-h5">
                      <!-- Card Header-->
                      <div class="row align-items-center">
                        <div class="col">
                          <h2 class="font-weight-bolder text-dark float-left">
                            {{ ideaTitle }}
                          </h2>
                          <span v-if="idea.ideaStatus.id == 1"
                            class="badge badge-primary font-weight-bolder text-uppercase ml-3 p-3 text-white bg-danger float-left">
                            {{ $t("IDEADETAIL.INBOZZA") }}
                          </span>
                        </div>
                        <div class="col mx-0 px-0 text-right">
                          <!--<ChangeDataLanguages :localizations="ideaLocalizations" :lang="lang" @changeLanguage="setLanguage($event)"></ChangeDataLanguages>-->
                        </div>
                      </div>
                      <div class="separator separator-solid separator-black opacity-100 my-2"></div>
                      <!--end::Card Header-->

                      <!--Master Assess-->
                      <div class="align-items-center" v-if="canAssessMasterF()">
                        <form v-if="!hasAssessMaster" class="form my-5" id="kt_form_evaluate_master">
                          <h3 class="pb-5">
                            {{ $t("IDEADETAIL.VALUTAZIONETITLE1") }}
                          </h3>

                          <div class="row mb-2 align-items-center">
                            <div class="col-12 col-md-6 align-items-center">
                              <!--Select-->
                              <b-form-select v-model="$v.form_evaluation_master
                                  .ideaSimpleAssessmentStatusId.$model
                                " :options="ideaSimpleAssessmentStatuses" :class="[
    'form-control bg-light-primary p-2',
    validationAssesmentStatus(
      $v.form_evaluation_master
        .ideaSimpleAssessmentStatusId,
      'Master'
    ),
  ]" value-field="id" text-field="name" disabled-field="notEnabled" @change="
  $v.form_evaluation_master
    .ideaSimpleAssessmentStatusId.$model == 1
    ? (isAssesmentFieldFilledMaster = false)
    : (isAssesmentFieldFilledMaster = true)
  "></b-form-select>
                            </div>

                            <div class="col-12 mt-2 mt-md-0 col-md-6 align-items-center">
                              <!--Number  //#qui -->
                              <b-form-input type="number" min="0" :class="[
                                'form-control-lg bg-light-primary',
                                validationGradeStatus(
                                  $v.form_evaluation_master.grade,
                                  'Master'
                                ),
                              ]" v-model="$v.form_evaluation_master.grade.$model"
                                v-bind:placeholder="$t('IDEADETAIL.PH1')" @change="
                                  $v.form_evaluation_master.grade.$model != ''
                                    ? (isGradeFieldFilledMaster = true)
                                    : (isGradeFieldFilledMaster = false)
                                  " @keyup="getGradeValueMaster" @wheel="(e) => e.target.blur()"></b-form-input>
                            </div>
                          </div>

                          <!--Description-->
                          <vue-editor id="input-evaluation-master" :editor-toolbar="customToolbar"
                            v-bind:placeholder="$t('IDEADETAIL.PH2')" :tag="'textarea'" v-model="$v.form_evaluation_master.assessment.$model
                              " class="mt-2 bg-light-primary" row="10">
                          </vue-editor>

                          <div class="row w-100 mt-5 m-0 p-0">
                            <div class="col-12 text-right m-0 p-0">
                              <button type="button" v-on:click="submitEvaluateMaster"
                                class="btn btn-warning btn-md text-uppercase font-weight-bold chat-send py-2 px-6">
                                {{ $t("IDEADETAIL.INVIA") }}
                              </button>
                            </div>
                          </div>
                        </form>
                        <h3 v-else class="py-2">
                          {{ $t("IDEADETAIL.VALUTAZIONETITLE5") }}
                        </h3>
                        <hr />
                      </div>

                      <!--Detail Assess-->
                      <div class="align-items-center" v-if="canAssessDetailF()">
                        <form v-if="!hasAssessDetail" class="form my-5" id="kt_form_evaluate">
                          <h3 class="pb-5">
                            {{ $t("IDEADETAIL.VALUTAZIONETITLE2") }}
                          </h3>

                          <div class="row mb-2 align-items-center">
                            <div class="col-12 col-md-6 align-items-center">
                              <!--Select // #qui -->
                              <b-form-select v-model="$v.form_evaluation
                                  .ideaSimpleAssessmentStatusId.$model
                                " :options="ideaSimpleAssessmentStatuses" :class="[
    'form-control bg-light-primary p-2',
    validationAssesmentStatus(
      $v.form_evaluation
        .ideaSimpleAssessmentStatusId,
      ''
    ),
  ]" value-field="id" text-field="name" disabled-field="notEnabled" @change="
  $v.form_evaluation
    .ideaSimpleAssessmentStatusId.$model == 1
    ? (isAssesmentFieldFilled = false)
    : (isAssesmentFieldFilled = true)
  "></b-form-select>
                            </div>

                            <div class="col-12 mt-2 mt-md-0 col-md-6 align-items-center">
                              <!--Number-->
                              <b-form-input type="number" min="0" :class="[
                                'form-control-lg bg-light-primary',
                                validationGradeStatus(
                                  $v.form_evaluation.grade,
                                  ''
                                ),
                              ]" v-model="$v.form_evaluation.grade.$model" v-bind:placeholder="$t('IDEADETAIL.PH1')"
                                @change="
                                  $v.form_evaluation.grade.$model != ''
                                    ? (isGradeFieldFilled = true)
                                    : (isGradeFieldFilled = false)
                                  " @keyup="getGradeValue" @wheel="(e) => e.target.blur()"></b-form-input>
                            </div>
                          </div>

                          <!--Description-->
                          <vue-editor id="input-evaluation" :editor-toolbar="customToolbar"
                            v-bind:placeholder="$t('IDEADETAIL.PH2')" :tag="'textarea'"
                            v-model="$v.form_evaluation.assessment.$model" class="mt-2 bg-light-primary" row="10">
                          </vue-editor>

                          <div class="row w-100 mt-5 m-0 p-0">
                            <div class="col-12 text-right m-0 p-0">
                              <button type="button" v-on:click="submitEvaluate"
                                class="btn btn-warning btn-md text-uppercase font-weight-bold chat-send py-2 px-6">
                                {{ $t("IDEADETAIL.INVIA") }}
                              </button>
                            </div>
                          </div>
                        </form>
                        <h3 v-else class="py-2">
                          {{ $t("IDEADETAIL.VALUTAZIONETITLE6") }}
                        </h3>
                        <hr />
                      </div>

                      <!--Master Result-->
                      <template v-if="this.publishAssessmentsMaster &&
                        this.idea &&
                        idea.ideaSimpleAssessmentStatus &&
                        this.idea.assessor
                        ">
                        <div class="d-flex flex-column my-5 align-items-start border border rounded p-3" :style="'border-color:' +
                          idea.ideaSimpleAssessmentStatus.color +
                          ' !important;'
                          ">
                          <!--Title-->
                          <h3 :style="'color:' + idea.ideaSimpleAssessmentStatus.color
                            ">
                            {{ $t("IDEADETAIL.VALUTAZIONETITLE3") }}
                          </h3>

                          <!--User-->
                          <router-link v-if="idea.assessor.profilePublic == true" :to="{
                            name: 'userDetail',
                            params: { id: idea.assessor.id },
                          }" target="_blank">
                            <div class="d-flex align-items-center mb-3 font-size-h5">
                              <div class="symbol symbol-circle symbol-40 mr-3" :style="$imageOptions.styleSettings({
                                width: 40,
                                height: 40,
                                userImage:
                                  idea.assessor.avatar &&
                                    idea.assessor.avatar.uri
                                    ? idea.assessor.avatar.uri
                                    : $imageOptions.defaultAvatarUrl,
                              })
                                "></div>
                              <span class="font-weight-bolder text-dark-75 font-size-h6">{{
                                idea.assessor.name +
                                " " +
                                idea.assessor.surname
                              }}</span>
                            </div>
                          </router-link>
                          <div v-else class="d-flex align-items-center mb-3 font-size-h5">
                            <div class="symbol symbol-circle symbol-40 mr-3" :style="$imageOptions.styleSettings({
                                  width: 40,
                                  height: 40,
                                  userImage:
                                    idea.assessor.avatar &&
                                      idea.assessor.avatar.uri
                                      ? idea.assessor.avatar.uri
                                      : $imageOptions.defaultAvatarUrl,
                                })
                                "></div>
                            <span class="font-weight-bolder text-dark-75 font-size-h6">{{ idea.assessor.username }}</span>
                          </div>

                          <!--Status-->
                          <div v-if="idea.ideaSimpleAssessmentStatus.id > 1"
                            class="rounded p-5 text-dark font-weight-bold font-size-lg w-100 d-flex justify-content-between align-items-center text-white font-size-h5"
                            :style="'color:#fff; background-color:' +
                              idea.ideaSimpleAssessmentStatus.color
                              ">
                            <span class="h4 text-white">{{
                              idea.ideaSimpleAssessmentStatus.localizations[
                                indexLanguage
                              ].name
                            }}</span>
                            <span v-if="idea.ideaSimpleAssessmentStatus.id == 8"
                              class="h5 font-weight-bolder text-white"><i
                                class="icon-2x text-white flaticon2-cup"></i></span>
                          </div>

                          <!--Assessment-->
                          <div v-if="(idea.assessment && idea.assessment != '') ||
                            (idea.grade != null && idea.grade >= 0)
                            "
                            class="mt-2 rounded p-5 bg-light-primary text-dark font-weight-bold font-size-lg w-100 align-items-center justify-content-between font-size-h5">
                            <div class="ql-editor reset-height" v-if="idea.assessment && idea.assessment != ''"
                              v-html="idea.assessment"></div>
                            <div class="text-right" v-if="idea.grade != null && idea.grade >= 0">
                              <div class="label label-lg label-rounded bg-white p-8 h2 font-weight-bolder">
                                {{ idea.grade }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <!--Results Assess-->
                      <template v-if="this.ideaSimpleAssessments &&
                        this.ideaSimpleAssessments.length > 0
                        ">
                        <div class="p-3">
                          <!--Title-->
                          <h3 class="py-2">
                            {{ $t("IDEADETAIL.VALUTAZIONETITLE4") }}
                          </h3>

                          <div class="d-flex flex-column my-5 align-items-start text-white font-size-h5"
                            v-for="item in ideaSimpleAssessments" :key="item.id">
                            <!--User-->
                            <router-link v-if="item.assessor.profilePublic == true ||
                              currentUser.id == item.assessor.id
                              " :to="{
    name: 'userDetail',
    params: { id: item.assessor.id },
  }" target="_blank">
                              <div class="d-flex align-items-center mb-3 font-size-h5">
                                <div class="symbol symbol-circle symbol-40 mr-3" :style="$imageOptions.styleSettings({
                                  width: 40,
                                  height: 40,
                                  userImage:
                                    item.assessor.avatar &&
                                      item.assessor.avatar.uri
                                      ? item.assessor.avatar.uri
                                      : $imageOptions.defaultAvatarUrl,
                                })
                                  "></div>
                                <span class="font-weight-bolder text-dark-75 font-size-h6">{{
                                  item.assessor.name +
                                  " " +
                                  item.assessor.surname
                                }}</span>
                              </div>
                            </router-link>
                            <div v-else class="d-flex align-items-center mb-3 font-size-h5">
                              <div class="symbol symbol-circle symbol-40 mr-3" :style="$imageOptions.styleSettings({
                                    width: 40,
                                    height: 40,
                                    userImage:
                                      item.assessor.avatar &&
                                        item.assessor.avatar.uri
                                        ? item.assessor.avatar.uri
                                        : $imageOptions.defaultAvatarUrl,
                                  })
                                  "></div>
                              <span class="font-weight-bolder text-dark-75 font-size-h6">{{ item.assessor.username
                              }}</span>
                            </div>

                            <!--Status-->
                            <div v-if="item.ideaSimpleAssessmentStatus.id > 1"
                              class="mt-2 rounded p-5 text-dark font-weight-bold font-size-lg w-100 d-flex justify-content-between align-items-center"
                              :style="'color:#fff; background-color:' +
                                item.ideaSimpleAssessmentStatus.color
                                ">
                              <span class="h4 text-white">{{
                                item.ideaSimpleAssessmentStatus.localizations[
                                  indexLanguage
                                ].name
                              }}</span>
                              <span v-if="item.ideaSimpleAssessmentStatus.id == 8"
                                class="h5 font-weight-bolder text-white"><i
                                  class="icon-2x text-white flaticon2-cup"></i></span>
                            </div>

                            <!--Assessment-->
                            <div v-if="(item.assessment && item.assessment != '') ||
                              (item.grade != null && item.grade >= 0)
                              "
                              class="mt-2 rounded p-5 bg-light-primary text-dark font-weight-bold font-size-lg w-100 align-items-center justify-content-between">
                              <div class="ql-editor reset-height" v-if="item.assessment && item.assessment != ''"
                                v-html="item.assessment"></div>
                              <div class="text-right" v-if="item.grade != null && item.grade >= 0">
                                <div class="label label-lg label-rounded bg-white p-8 h2 font-weight-bolder">
                                  {{ item.grade }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>

          <!--Versioning list-->
          <div v-else-if="this.versionsReverse && this.versionsReverse.length > 0" class="row mx-1 mt-5">
            <div v-for="item in this.versionsReverse" :key="item.id" class="col-xl-12 mb-4 align-items-center">
              <ListIdeaCardVersioning :idea="item" :process_id="process_id" :phase_id="phase_id"
                :phase_status_id="phase_status_id" :module_id="module_id" :see_tabs="see_tabs" :module="module"
                :phase="phase" :idPublicIdea="idPublicIdea" @changeSeeTabs="see_tabs = true"></ListIdeaCardVersioning>
            </div>
          </div>
          <!--end::Versioning list-->
        </div>
      </div>
    </div>

    <!--:: TOOLS-->
    <div class="d-none d-lg-block col-lg-1 col-xl-1 justify-content-between align-items-center">
      <div class="d-flex flex-column mt-5 mr-5 position-fixed">
        <!--:: Voto -->

        <div id="kt_quick_panel_toggle" class="d-flex" v-if="voteOpened && !is_anonymous">
          <router-link v-slot="{ href, navigate }" :to="{}">
            <a :href="href" class="mb-5 btn btn-icon bg-warning mx-auto btn-lg px-2" @click="navigate" id="pop-vote">
              <span class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-warning text-white">
                  <i class="fas fa-star text-white pr-0 pb-1"></i>
                </span>
              </span>
            </a>
          </router-link>
        </div>
        <b-popover target="pop-vote" triggers="hover">
          <template v-slot:title>{{ $t("IDEADETAIL.TUOIVOTI") }}</template>
        </b-popover>

        <div class="d-flex flex-column">
          <!--:: Gestione pulsante Commenti -->
          <div id="pop-comment" class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2" v-if="canComment() && this.activeCommenting && this.see_tabs == true
            ">
            <div v-if="is_anonymous_on_public">
              <a v-b-modal.modalLoginRegisterProcessOnPublic class="bg-transparent symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                  <!--                  <inline-svg src="/assets/media/bipart/btn-collaboration-base.svg" style=" width:60%;" />-->
                  <span class="svg-icon svg-icon-white svg-icon-2x">
                    <i class="fas fa-comment text-white 2x"></i>
                    <!--                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                    <!--                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                    <!--                          <rect x="0" y="0" width="24" height="24"/>-->
                    <!--                          <path d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L6,18 C4.34314575,18 3,16.6568542 3,15 L3,6 C3,4.34314575 4.34314575,3 6,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                          <path d="M7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 Z M12.5,12 C11.6715729,12 11,11.3284271 11,10.5 C11,9.67157288 11.6715729,9 12.5,9 C13.3284271,9 14,9.67157288 14,10.5 C14,11.3284271 13.3284271,12 12.5,12 Z M17.5,12 C16.6715729,12 16,11.3284271 16,10.5 C16,9.67157288 16.6715729,9 17.5,9 C18.3284271,9 19,9.67157288 19,10.5 C19,11.3284271 18.3284271,12 17.5,12 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                      </g>-->
                    <!--                    </svg>-->
                  </span>
                </span>
              </a>
            </div>
            <div v-else-if="!is_anonymous_on_public && is_mobile_pending && is_email_pending
              ">
              <a @click.prevent="alertEmailMobileConfirm" class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                  <span class="svg-icon svg-icon-white svg-icon-2x">
                    <i class="fas fa-comment text-white 2x"></i>
                    <!--                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                    <!--                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                    <!--                          <rect x="0" y="0" width="24" height="24"/>-->
                    <!--                          <path d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L6,18 C4.34314575,18 3,16.6568542 3,15 L3,6 C3,4.34314575 4.34314575,3 6,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                          <path d="M7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 Z M12.5,12 C11.6715729,12 11,11.3284271 11,10.5 C11,9.67157288 11.6715729,9 12.5,9 C13.3284271,9 14,9.67157288 14,10.5 C14,11.3284271 13.3284271,12 12.5,12 Z M17.5,12 C16.6715729,12 16,11.3284271 16,10.5 C16,9.67157288 16.6715729,9 17.5,9 C18.3284271,9 19,9.67157288 19,10.5 C19,11.3284271 18.3284271,12 17.5,12 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                      </g>-->
                    <!--                    </svg>-->
                  </span>
                </span>
              </a>
            </div>
            <div v-else-if="!is_anonymous_on_public &&
              is_mobile_pending &&
              !is_email_confirmed
              ">
              <a @click.prevent="alertMobileConfirm" class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                  <span class="svg-icon svg-icon-white svg-icon-2x">
                    <i class="fas fa-comment text-white 2x"></i>
                    <!--                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                    <!--                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                    <!--                          <rect x="0" y="0" width="24" height="24"/>-->
                    <!--                          <path d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L6,18 C4.34314575,18 3,16.6568542 3,15 L3,6 C3,4.34314575 4.34314575,3 6,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                          <path d="M7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 Z M12.5,12 C11.6715729,12 11,11.3284271 11,10.5 C11,9.67157288 11.6715729,9 12.5,9 C13.3284271,9 14,9.67157288 14,10.5 C14,11.3284271 13.3284271,12 12.5,12 Z M17.5,12 C16.6715729,12 16,11.3284271 16,10.5 C16,9.67157288 16.6715729,9 17.5,9 C18.3284271,9 19,9.67157288 19,10.5 C19,11.3284271 18.3284271,12 17.5,12 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                      </g>-->
                    <!--                    </svg>-->
                  </span>
                </span>
              </a>
            </div>
            <div v-else-if="!is_anonymous_on_public &&
              is_email_pending &&
              !is_mobile_confirmed
              ">
              <a @click.prevent="alertEmailConfirm" class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                  <span class="svg-icon svg-icon-white svg-icon-2x">
                    <i class="fas fa-comment text-white 2x"></i>
                    <!--                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                    <!--                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                    <!--                          <rect x="0" y="0" width="24" height="24"/>-->
                    <!--                          <path d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L6,18 C4.34314575,18 3,16.6568542 3,15 L3,6 C3,4.34314575 4.34314575,3 6,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                          <path d="M7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 Z M12.5,12 C11.6715729,12 11,11.3284271 11,10.5 C11,9.67157288 11.6715729,9 12.5,9 C13.3284271,9 14,9.67157288 14,10.5 C14,11.3284271 13.3284271,12 12.5,12 Z M17.5,12 C16.6715729,12 16,11.3284271 16,10.5 C16,9.67157288 16.6715729,9 17.5,9 C18.3284271,9 19,9.67157288 19,10.5 C19,11.3284271 18.3284271,12 17.5,12 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                      </g>-->
                    <!--                    </svg>-->
                  </span>
                </span>
              </a>
            </div>
            <div v-else-if="!is_anonymous_on_public && !userHasProcess(this.process.id)
              ">
              <a v-b-modal.modalLoggedRegisterProcessOnPublic class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                  <!--                  <inline-svg src="/assets/media/bipart/btn-collaboration-base.svg" style=" width:60%;" />-->
                  <span class="svg-icon svg-icon-white svg-icon-2x">
                    <i class="fas fa-comment text-white 2x"></i>

                    <!--                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                    <!--                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                    <!--                          <rect x="0" y="0" width="24" height="24"/>-->
                    <!--                          <path d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L6,18 C4.34314575,18 3,16.6568542 3,15 L3,6 C3,4.34314575 4.34314575,3 6,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                          <path d="M7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 Z M12.5,12 C11.6715729,12 11,11.3284271 11,10.5 C11,9.67157288 11.6715729,9 12.5,9 C13.3284271,9 14,9.67157288 14,10.5 C14,11.3284271 13.3284271,12 12.5,12 Z M17.5,12 C16.6715729,12 16,11.3284271 16,10.5 C16,9.67157288 16.6715729,9 17.5,9 C18.3284271,9 19,9.67157288 19,10.5 C19,11.3284271 18.3284271,12 17.5,12 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                      </g>-->
                    <!--                    </svg>-->
                  </span>
                </span>
              </a>
            </div>
            <div v-else>
              <a @click="scrollMeToComment('tabs')" class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                  <span class="svg-icon svg-icon-white svg-icon-2x">
                    <i class="fas fa-comment text-white 2x"></i>
                    <!--                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
                    <!--                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
                    <!--                        <rect x="0" y="0" width="24" height="24"/>-->
                    <!--                        <path d="M21.9999843,15.009808 L22.0249378,15 L22.0249378,19.5857864 C22.0249378,20.1380712 21.5772226,20.5857864 21.0249378,20.5857864 C20.7597213,20.5857864 20.5053674,20.4804296 20.317831,20.2928932 L18.0249378,18 L6,18 C4.34314575,18 3,16.6568542 3,15 L3,6 C3,4.34314575 4.34314575,3 6,3 L19,3 C20.6568542,3 22,4.34314575 22,6 L22,15 C22,15.0032706 21.9999948,15.0065399 21.9999843,15.009808 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                        <path d="M7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 Z M12.5,12 C11.6715729,12 11,11.3284271 11,10.5 C11,9.67157288 11.6715729,9 12.5,9 C13.3284271,9 14,9.67157288 14,10.5 C14,11.3284271 13.3284271,12 12.5,12 Z M17.5,12 C16.6715729,12 16,11.3284271 16,10.5 C16,9.67157288 16.6715729,9 17.5,9 C18.3284271,9 19,9.67157288 19,10.5 C19,11.3284271 18.3284271,12 17.5,12 Z" fill="#000000" opacity="0.6"/>-->
                    <!--                    </g>-->
                    <!--                </svg> -->
                  </span>
                </span>
              </a>
            </div>
          </div>
          <b-popover target="pop-comment" triggers="hover">
            <template v-slot:title>{{
              $t("IDEADETAIL.COMMENTAIDEA")
            }}</template>
          </b-popover>
          <!-- END:: Gestione pulsante Commenti  -->

          <!-- Gestione pulsante Follow -->
          <template v-if="canFollow && this.see_tabs == true">
            <div v-if="is_anonymous_on_public">
              <!-- Gestione registrazione avanzata follow -->
              <!--             #qui desktop -->
              <div v-if="this.module.advancedRegistrationRequiredForFollowing">
                <a class="bg-transparent" @click="advIncFollower($event)">
                  <div class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2" id="pop-follow-adv">
                    <span class="symbol symbol-rounded">
                      <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                        <i class="fas fa-heart text-white"></i>
                      </span>
                    </span>
                  </div>
                </a>
                <b-popover target="pop-follow-adv" triggers="hover">
                  <template v-slot:title>{{
                    $t("IDEADETAIL.SEGUIIDEA")
                  }}</template>
                </b-popover>
              </div>

              <!-- Gestione registrazione base follow -->
              <div v-else>
                <a v-b-modal.modalLoginRegisterProcessOnPublic class="bg-transparent" id="pop-plus"
                  @click="followeSet = true">
                  <!--<a v-b-modal.modalLoginRegisterProcessOnPublic class="bg-transparent" id="pop-plus">-->
                  <div class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2" id="pop-follow">
                    <span class="symbol symbol-rounded">
                      <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                        <i class="fas fa-heart text-white"></i>
                      </span>
                    </span>
                  </div>
                </a>
                <b-popover target="pop-follow" triggers="hover">
                  <template v-slot:title>{{
                    $t("IDEADETAIL.SEGUIIDEA")
                  }}</template>
                </b-popover>
              </div>
            </div>
            <div v-else-if="!is_anonymous_on_public && is_mobile_pending && is_email_pending
                ">
              <a @click.prevent="
                alertEmailMobileConfirm();
              followeSet = true;
              ">
                <button class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2" id="pop-follow">
                  <span class="symbol symbol-rounded">
                    <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                      <i class="fas fa-heart text-white"></i>
                    </span>
                  </span>
                </button>
              </a>
              <b-popover target="pop-follow" triggers="hover">
                <template v-slot:title>{{
                  $t("IDEADETAIL.SEGUIIDEA")
                }}</template>
              </b-popover>
            </div>
            <div v-else-if="!is_anonymous_on_public &&
                is_mobile_pending &&
                !is_email_confirmed
                ">
              <a @click.prevent="
                alertMobileConfirm();
              followeSet = true;
              ">
                <button class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2" id="pop-follow">
                  <span class="symbol symbol-rounded">
                    <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                      <i class="fas fa-heart text-white"></i>
                    </span>
                  </span>
                </button>
              </a>
              <b-popover target="pop-follow" triggers="hover">
                <template v-slot:title>{{
                  $t("IDEADETAIL.SEGUIIDEA")
                }}</template>
              </b-popover>
            </div>
            <div v-else-if="!is_anonymous_on_public &&
                is_email_pending &&
                !is_mobile_confirmed
                ">
              <a @click.prevent="
                alertEmailConfirm();
              followeSet = true;
              ">
                <button class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2" id="pop-follow">
                  <span class="symbol symbol-rounded">
                    <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                      <i class="fas fa-heart text-white"></i>
                    </span>
                  </span>
                </button>
              </a>
              <b-popover target="pop-follow" triggers="hover">
                <template v-slot:title>{{
                  $t("IDEADETAIL.SEGUIIDEA")
                }}</template>
              </b-popover>
            </div>
            <div v-else-if="!is_anonymous_on_public && !userHasProcess(this.process.id)
                ">
              <a v-b-modal.modalLoggedRegisterProcessOnPublic id="pop-plus" @click="followeSet = true">
                <div class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2" id="pop-follow">
                  <span class="symbol symbol-rounded">
                    <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                      <i class="fas fa-heart text-white"></i>
                    </span>
                  </span>
                </div>
              </a>
            </div>
            <div v-else>
              <template v-if="idea.followedByAuthUser">
                <span v-if="this.module.advancedRegistrationRequiredForFollowing">
                  <a @click="advDecFollower($event)" class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2"
                    id="pop-unfollow">
                    <span class="symbol symbol-rounded">
                      <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2"
                        style="position: relative">
                        <i class="fas fa-heart text-white" style="
                            position: absolute;
                            bottom: 50%;
                            transform: translateY(50%);
                            font-size: 1.1em;
                          "></i>
                        <i class="fas fa-heart text-warning" style="
                            position: absolute;
                            bottom: 50%;
                            transform: translateY(54%);
                            font-size: 0.9em;
                          "></i>
                      </span>
                    </span>
                  </a>
                </span>
                <span v-else>
                  <a @click="decrementFollower($event)" class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2"
                    id="pop-unfollow">
                    <span class="symbol symbol-rounded">
                      <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2"
                        style="position: relative">
                        <i class="fas fa-heart text-white" style="
                            position: absolute;
                            bottom: 50%;
                            transform: translateY(50%);
                            font-size: 1.1em;
                          "></i>
                        <i class="fas fa-heart text-warning" style="
                            position: absolute;
                            bottom: 50%;
                            transform: translateY(54%);
                            font-size: 0.9em;
                          "></i>
                      </span>
                    </span>
                  </a>
                </span>
                <b-popover target="pop-unfollow" triggers="hover">
                  <template v-slot:title>{{
                    $t("IDEADETAIL.NONSEGUIRE")
                  }}</template>
                </b-popover>
              </template>
              <template v-else>
                <span v-if="this.module.advancedRegistrationRequiredForFollowing">
                  <a @click="advIncFollower($event)" class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2"
                    id="pop-follow">
                    <span class="symbol symbol-rounded">
                      <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                        <i class="fas fa-heart text-white"></i>
                      </span>
                    </span>
                  </a>
                </span>
                <span v-else>
                  <a @click="incrementFollower($event)" class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2"
                    id="pop-follow">
                    <span class="symbol symbol-rounded">
                      <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                        <i class="fas fa-heart text-white"></i>
                      </span>
                    </span>
                  </a>
                </span>
              </template>
              <b-popover target="pop-follow" triggers="hover">
                <template v-slot:title>{{
                  $t("IDEADETAIL.SEGUIIDEA")
                }}</template>
              </b-popover>
            </div>
          </template>

          <!-- Gestione pulsante Contatta_il_proponente -->
          <div v-if="flagMessage" id="pop-contact-owner" class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2">
            <div v-if="is_anonymous_on_public">
              <a v-b-modal.modalLoginRegisterProcessOnPublic class="bg-transparent symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                  <inline-svg src="/assets/media/bipart/btn-collaboration-base.svg" style="width: 60%" />
                </span>
              </a>
            </div>
            <div v-else-if="!is_anonymous_on_public && is_email_blank" @click="forceRenderContactOwner">
              <a @click.prevent="alertEmailBlank" class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                  <inline-svg src="/assets/media/bipart/btn-collaboration-base.svg" style="width: 60%" />
                </span>
              </a>
            </div>
            <div v-else-if="!is_anonymous_on_public && is_email_pending" @click="forceRenderContactOwner">
              <a @click.prevent="alertEmailConfirm" class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                  <inline-svg src="/assets/media/bipart/btn-collaboration-base.svg" style="width: 60%" />
                </span>
              </a>
            </div>
            <div v-else-if="!is_anonymous_on_public && !userHasProcess(this.process.id)
              ">
              <a v-b-modal.modalLoggedRegisterProcessOnPublic class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                  <inline-svg src="/assets/media/bipart/btn-collaboration-base.svg" style="width: 60%" />
                </span>
              </a>
            </div>
            <div v-else-if="!can_access_module('message')">
              <a @click="alertUserModuleAccessBitmaskMessage()" class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                  <inline-svg src="/assets/media/bipart/btn-collaboration-base.svg" style="width: 60%" />
                </span>
              </a>
            </div>
            <div v-else>
              <a v-b-modal.modalContactOwner class="symbol symbol-rounded" v-if="isContactOwner">
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2"
                  @click="forceRenderContactOwner">
                  <inline-svg src="/assets/media/bipart/btn-collaboration-base.svg" style="width: 60%" />
                </span>
              </a>
              <a class="symbol symbol-rounded" v-else style="position: relative">
                <div style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border: none;
                    border-radius: 0.42rem;
                    background-color: white;
                    opacity: 0.5;
                  "></div>
                <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white pb-2">
                  <inline-svg src="/assets/media/bipart/btn-collaboration-base.svg" style="width: 60%" />
                </span>
              </a>
            </div>
          </div>
          <b-popover target="pop-contact-owner" triggers="hover">
            <template v-slot:title>{{
              $t("IDEADETAIL.MCONTATTAILPROPONENTE")
            }}</template>
          </b-popover>
          <!-- END:: Gestione pulsante Contatta_il_proponente -->

          <!--:: Share -->
          <!-- <router-link
              v-if="phase.phaseStatus.id==2 || adminRoles.includes(currentUser.authorities[0])"
              v-slot="{ href, navigate }"
            :to="{
              name: '',
              params: { process_id: process_id }
            }"
          >
            <a
              :href="href"
              class="mb-5 btn btn-icon bg-primary  mx-auto  d-flex btn-lg px-2"
              @click="navigate"
              id="pop-share"
            >
              <span class="symbol symbol-rounded">
              <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                <span class="svg-icon svg-icon-white svg-icon-2x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M10.9,2 C11.4522847,2 11.9,2.44771525 11.9,3 C11.9,3.55228475 11.4522847,4 10.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,16 C20,15.4477153 20.4477153,15 21,15 C21.5522847,15 22,15.4477153 22,16 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L10.9,2 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M24.0690576,13.8973499 C24.0690576,13.1346331 24.2324969,10.1246259 21.8580869,7.73659596 C20.2600137,6.12944276 17.8683518,5.85068794 15.0081639,5.72356847 L15.0081639,1.83791555 C15.0081639,1.42370199 14.6723775,1.08791555 14.2581639,1.08791555 C14.0718537,1.08791555 13.892213,1.15726043 13.7542266,1.28244533 L7.24606818,7.18681951 C6.93929045,7.46513642 6.9162184,7.93944934 7.1945353,8.24622707 C7.20914339,8.26232899 7.22444472,8.27778811 7.24039592,8.29256062 L13.7485543,14.3198102 C14.0524605,14.6012598 14.5269852,14.5830551 14.8084348,14.2791489 C14.9368329,14.140506 15.0081639,13.9585047 15.0081639,13.7695393 L15.0081639,9.90761477 C16.8241562,9.95755456 18.1177196,10.0730665 19.2929978,10.4469645 C20.9778605,10.9829796 22.2816185,12.4994368 23.2042718,14.996336 L23.2043032,14.9963244 C23.313119,15.2908036 23.5938372,15.4863432 23.9077781,15.4863432 L24.0735976,15.4863432 C24.0735976,15.0278051 24.0690576,14.3014082 24.0690576,13.8973499 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        transform="translate(15.536799, 8.287129) scale(-1, 1) translate(-15.536799, -8.287129) "
                      />
                    </g>
                  </svg>
                </span>
              </span>
              </span>
            </a>
          </router-link>
          <b-popover target="pop-share" triggers="hover">
            <template v-slot:title>Condividi idea</template>
          </b-popover> -->

          <!--:: Valutazione -->
          <a v-if="seeEvaluateButton() && this.see_tabs == true"
            class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2" @click="scrollMeToEvaluate('tabs')"
            id="pop-evaluate">
            <span class="symbol symbol-rounded">
              <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                <i class="fas fa-tasks text-white"></i>
              </span>
            </span>
          </a>
          <b-popover target="pop-evaluate" triggers="hover">
            <template v-slot:title>{{ $t("IDEADETAIL.VALUTAIDEA") }}</template>
          </b-popover>

          <!--:: Modifica idea -->
          <a class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2" v-if="canModify() && module.canUpdate && this.see_tabs == true" @click="editIdea(idea.id)" id="pop-edit">
            <span class="symbol symbol-rounded">
              <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                <span class="svg-icon svg-icon-white svg-icon-2x">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                        fill="#000000" fill-rule="nonzero"
                        transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " />
                      <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1" />
                    </g>
                  </svg></span>
              </span>
            </span>
          </a>
          <b-popover target="pop-edit" triggers="hover">
            <template v-slot:title>{{
              $t("IDEADETAIL.MODIFICAIDEA")
            }}</template>
          </b-popover>

          <!--:: Cancella idea -->
          <a v-if="canDelete() && module.canDelete && this.see_tabs == true" href="#"
            class="mb-5 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2" @click="deleteIdea($event)" id="pop-delete">
            <span class="symbol symbol-rounded">
              <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                <span class="svg-icon svg-icon-white svg-icon-2x">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                    height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
                        fill="#000000" fill-rule="nonzero" />
                      <path
                        d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                        fill="#000000" opacity="0.3" />
                    </g>
                  </svg></span>
              </span>
            </span>
          </a>
          <b-popover target="pop-delete" triggers="hover">
            <template v-slot:title>{{
              $t("IDEADETAIL.CANCELLAIDEA")
            }}</template>
          </b-popover>
        </div>

        <!-- Social sharing -->
        <div id="pop-share" class="btn-group mr-1" role="group">
          <a v-b-modal.modalSocialSharing class="mb-5 btn btn-icon w-auto d-flex btn-lg px-2">
            <span class="symbol symbol-rounded">
              <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
                <i class="fas fa-share-alt text-white icon-2x pb-2"></i>
              </span>
            </span>
          </a>
        </div>
        <b-popover target="pop-share" triggers="hover">
          <template v-slot:title>{{ $t("IDEADETAIL.CONDIVIDI") }}</template>
        </b-popover>

        <!--:: Assistenza -->
        <a href="#" class="mb-5 btn btn-icon w-auto d-flex btn-lg px-2" @click.prevent="alertHelp" id="pop-help">
          <span class="symbol symbol-rounded">
            <span class="symbol-label font-size-h3 font-weight-bold bg-primary text-white">
              <i class="far fa-life-ring text-white icon-2x pb-2"></i>
            </span>
          </span>
        </a>
        <b-popover target="pop-help" triggers="hover">
          <template v-slot:title>{{
            $t("IDEADETAIL.RICHIEDIASSISTENZA")
          }}</template>
        </b-popover>
      </div>
    </div>

    <!-- Necessario per la gestione dell'html inserito dall'editor -->
    <vue-editor id="input-hidden" placeholder="" :tag="'textarea'" class="bg-light-primary" row="1" hidden></vue-editor>

    <KTQuickPanelManual :process="process" ref="userVotesPanel"></KTQuickPanelManual>

    <ContactOwner ref="contactOwner" :idea="idea" :module="module" @ownerMailed="manageOwnerMailed"
      :key="contactOwnerKey"></ContactOwner>

    <SocialSharing ref="socialSharing" :idea="idea" :url="this.currentURL"></SocialSharing>

    <RegisterOnPublic ref="registerOnPublic2" :process="process" @switchToLogin="manageLoginModal"
      @userRegisteredToProcess="manageUserRegisteredToProcess($event)"></RegisterOnPublic>
    <LoginRegisterProcessOnPublic ref="loginRegisterProcessOnPublic" :process="process"
      @switchToRegister="manageRegisterModal" @userLoggedInAndRegisteredToProcess="
        manageUserLoggedInAndRegisteredToProcess()
        " @userLoggedIn="manageUserLoggedIn"></LoginRegisterProcessOnPublic>
    <LoggedRegisterProcessOnPublic ref="loggedRegisterProcessOnPublic" :process="process" @userLoggedRegisteredToProcess="
      manageUserLoggedRegisteredToProcess($event)
      "></LoggedRegisterProcessOnPublic>

    <!-- Advanced Registration modals -->
    <adv-method-auth ref="methodAuth"></adv-method-auth>
    <adv-role-auth ref="roleAuth" @switchToMobile="manageMobile"></adv-role-auth>
    <adv-mobile-auth ref="mobileAuth" :process="process" @switchToMobileVerify="manageMobileVerify"
      @switchToPasswordRecovery="managePasswordRecovery"></adv-mobile-auth>
    <adv-mobile-verify ref="mobileVerify" @switchToTaxcode="manageTaxcode"></adv-mobile-verify>
    <adv-taxcode-auth ref="taxcodeAuth" :process="process" :module="module"
      @switchToTaxcodeVerified="manageTaxcodeVerified"></adv-taxcode-auth>
    <adv-auth-taxcode-verified ref="taxcodeVerified" :process="process"></adv-auth-taxcode-verified>
    <adv-auth-taxcode-not-valid ref="taxcodeNotValid" :process="process"></adv-auth-taxcode-not-valid>

    <adv-register-on-public ref="registerOnPublic" :process="process"
      @userRegistered="manageUserRegistered"></adv-register-on-public>
    <adv-login-on-public ref="loginOnPublic" :process="process" @userLoggedIn="manageUserLogged"></adv-login-on-public>
    <!-- End Advanced Registration modals -->

    <!-- Recovery password modals -->
    <password-recovery ref="passwordRecovery" @switchToOTPVerify="manageOTPVerify">
    </password-recovery>
    <password-mobile-verify ref="passwordMobileVerify"
      @switchToPasswordChange="managePasswordChange"></password-mobile-verify>
    <password-change ref="passwordChange"></password-change>
    <!-- End Recovery password modals -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import Phaseline from "@/view/layout/common/Phaseline";
import KTQuickPanelManual from "@/view/layout/extras/offcanvas/QuickPanelManual.vue";
import Swal from "sweetalert2";
import moment from "moment";
import ListIdeaCardVersioning from "@/view/pages/modules/ideas/partials/ListIdeaCardVersioning";
import ButtonMobile from "@/view/content/ButtonMobile.vue";

// import {SET_CURRENT_PROCESS} from "@/core/services/store/processes.module";

import { ideaMixin } from "@/mixins/idea";
import { processMixin } from "@/mixins/process";
import { alertMixin } from "@/mixins/alert";
import { matrixLight } from "@/mixins/matrixLight";

import { gmapApi } from "gmap-vue";
import { VueEditor } from "vue2-editor";

import RegisterOnPublic from "@/view/pages/auth/partials/RegisterOnPublic";
import ContactOwner from "@/view/pages/modules/ideas/partials/ContactOwner";
import JwtService from "@/core/services/jwt.service";
import { RESEND_OTP_CODE } from "@/core/services/store/auth.module";

import LoginRegisterProcessOnPublic from "@/view/pages/auth/partials/LoginRegisterProcessOnPublic";
import LoggedRegisterProcessOnPublic from "@/view/pages/auth/partials/LoggedRegisterProcessOnPublic";

import AdvRoleAuth from "@/view/pages/auth/partials/advAuthRole";
import AdvMobileAuth from "@/view/pages/auth/partials/advAuthMobile";
import AdvMobileVerify from "@/view/pages/auth/partials/advAuthMobileVerify";
import AdvTaxcodeAuth from "@/view/pages/auth/partials/advAuthTaxcode";
import AdvAuthTaxcodeVerified from "@/view/pages/auth/partials/advAuthTaxcodeVerified";
import AdvAuthTaxcodeNotValid from "@/view/pages/auth/partials/advAuthTaxcodeNotValid";
import AdvMethodAuth from "@/view/pages/auth/partials/advAuthMethod";
import AdvRegisterOnPublic from "@/view/pages/auth/partials/advRegisterOnPublic";
import AdvLoginOnPublic from "@/view/pages/auth/partials/advLoginOnPublic";
import ChangeDataLanguages from "@/view/layout/common/ChangeDataLanguages.vue";
import SocialSharing from "@/view/pages/modules/ideas/partials/SocialSharing";

import PasswordRecovery from "@/view/pages/auth/partials/PasswordRecovery";
import PasswordMobileVerify from "@/view/pages/auth/partials/PasswordMobileVerify";
import PasswordChange from "@/view/pages/auth/partials/PasswordChange";

import i18nService from "@/core/services/i18n.service";
import { validationMixin } from "vuelidate";
import {
  required,
  minValue,
  integer,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "IdeaDetail",
  mixins: [ideaMixin, processMixin, alertMixin, validationMixin, matrixLight],
  components: {
    Phaseline,
    KTQuickPanelManual,
    VueEditor,
    RegisterOnPublic,
    ContactOwner,
    LoginRegisterProcessOnPublic,
    LoggedRegisterProcessOnPublic,
    AdvMethodAuth,
    AdvAuthTaxcodeVerified,
    AdvAuthTaxcodeNotValid,
    AdvMobileVerify,
    AdvTaxcodeAuth,
    AdvMobileAuth,
    AdvRoleAuth,
    AdvRegisterOnPublic,
    AdvLoginOnPublic,
    ChangeDataLanguages,
    SocialSharing,
    ListIdeaCardVersioning,

    PasswordMobileVerify,
    PasswordRecovery,
    PasswordChange,

    ButtonMobile,
  },
  props: ["phases"],
  data() {
    return {
      contactOwnerKey: 0,
      isContactOwner: true, // di base true, perché non è un problema grave se l'utente manda un msg a se stesso
      processVoteIdForVoteType4: false,
      publishValueVotes1: false,
      moduleContentType: null,
      flagMessage: false,

      followeSet: false,

      hasForbiddenCharacters: null,
      donationValue: null,

      isAssesmentFieldFilled: null,
      isGradeFieldFilled: null,
      isAssesmentFieldFilledMaster: null,
      isGradeFieldFilledMaster: null,
      form_evaluationGradeValue: null,
      form_evaluationMasterGradeValue: null,
      tempGradeValue: 0,
      validatedValue: null,
      hasProcessManyPhases: null,
      hasPhaseManyModules: null,
      hasModuleManyIdeas: null,

      idea2FollowersCounter: 0,
      displayViewMap: false,
      ideaLocalizations: [],
      forced_evaluate:
        this.$route.query && this.$route.query.forced_evaluate
          ? this.$route.query.forced_evaluate
          : false,
      publishVotes: false,
      hasAssessMaster: false,
      hasAssessDetail: false,
      ideaSimpleAssessments: [],
      publishAssessmentsMaster: false,
      publishAssessmentsDetail: false,
      canAssessMaster: false,
      canAssessDetail: false,
      ideaSimpleAssessmentStatuses: [],
      maxVersionsPerContent: 0,
      maxCompletionDaysPerVersion: 0,
      maxCompletionDaysPerVersionDate: "",
      activeCommenting: false,
      moduleDateStr: "",
      openDate: null,
      closeDate: null,
      idPublicIdea: 0,
      see_tabs: true,
      ideaTitle: "",
      ideaDescription: "",
      ideaNarration: "",
      lang: "",
      defaultlang: "",
      indexLanguage: 0,
      versions: [],
      versionsReverse: [],
      idIdeaToVersioning: 0,
      idIdeaDraft: 0,
      existsUnpubVersionInGroup: false,
      versionNumber: 0,
      id: this.$route.params.id,
      process_id: this.$route.params.process_id,
      phase_id: this.$route.params.phase_id,
      module_id: this.$route.params.module_id,
      phase_status_id: this.$route.params.phase_status_id,
      process: [],
      phase: [],
      module: [],
      idea: [],
      tabindex: 0,
      tabindexString: "",
      agendas: [],

      percentage: 0,
      voto4: "",
      voto4_origin: "",
      video: [],
      images: [],
      attaches: [],
      youtubeLink: "",

      new_comment: "",
      commentNumber: 0,
      ideaAssessmentNumber: 0,
      challengesNumber: 0,
      scenariosNumber: 0,
      // idea_voted: false,
      voteParams: [],

      allAuthRoles: [
        "ADMIN",
        "FACILITATOR",
        "ASSESSOR",
        "EXPERT",
        "DELEGATE",
        "AUTH_CITIZEN",
      ],
      adminRoles: ["ADMIN", "FACILITATOR"],
      evaluateRoles: ["ADMIN", "FACILITATOR", "ASSESSOR", "EXPERT"],

      selected: "C",
      /*options: [
         { item: 'A', name: this.$t("IDEADETAIL.APPROVATA") },
         { item: 'B', name: this.$t("IDEADETAIL.NONAPPROVATA") },
         { item: 'C', name: this.$t("IDEADETAIL.NONVALUTATA") }
       ],*/

      map: null,
      myCoordinates: {
        lat: 0,
        lng: 0,
      },
      zoom: 14,
      markerIcon: "/assets/media/bipart/pinpoint.svg",
      marker: {},

      /*
      |--------------------------------------------------------------------------
      | EDITOR
      |--------------------------------------------------------------------------
      */
      customToolbar: [
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],

      /* COMMON STRING */
      borderSubtitle: "",

      moduleVote1IsOpen: false,
      moduleVote4IsOpen: false,

      form_evaluation: {
        assessment: "",
        grade: "",
        ideaSimpleAssessmentStatusId: 1,
      },

      form_evaluation_master: {
        assessment: "",
        grade: "",
        ideaSimpleAssessmentStatusId: 1,
      },

      addPreferenceSet: false,
      delPreferenceSet: false,
      addWalletSet: false,

      currentURL: "http://" + this.$currentHost + window.location.pathname,

      selectedImage: null,
      ideaOgTitle: "",
      ideaOgDescription: "",
      ideaOgLogo: "",
    };
  },
  head: {
    title: function () {
      return {
        inner: this.ideaOgTitle,
      };
    },
    meta: function () {
      return [
        { p: "og:url", c: this.currentURL, id: "og-url" },
        { p: "og:title", c: this.ideaOgTitle, id: "og-title" },
        {
          p: "og:description",
          c: this.ideaOgDescription,
          id: "og-description",
        },
        { p: "og:image", c: this.ideaOgLogo, id: "og-logo" },
      ];
    },
  },
  validations: {
    form_evaluation: {
      assessment: {
        //required
      },
      grade: {
        required,
        minValue: minValue(0),
        integer,
        maxLength: maxLength(6),
      },
      ideaSimpleAssessmentStatusId: {
        required,
        minValue: minValue(2),
      },
    },

    form_evaluation_master: {
      assessment: {
        //required
      },
      grade: {
        required,
        minValue: minValue(0),
        integer,
        maxLength: maxLength(6),
      },
      ideaSimpleAssessmentStatusId: {
        required,
        minValue: minValue(2),
      },
    },
  },
  filters: {
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser", "getOrganization"]),

    voteOpened() {
      return this.moduleVote1IsOpen || this.moduleVote4IsOpen;
    },
    getLogo() {
      return this.idea.logo ? this.idea.logo.uri : "";
    },
    is_anonymous_on_public() {
      if (this.getOrganization.data) {
        return (
          this.getOrganization.data.publiclyVisible && this.currentUser.id == 5
        );
      } else {
        return this.currentUser.id === 5;
      }
    },
    is_anonymous() {
      return (
        this.currentUser.authorities &&
        this.currentUser.authorities.includes("SCOUT")
      );
    },
    is_email_pending() {
      return (
        this.currentUser.selectedOrganizationPublic &&
        this.currentUser.emailRegStatus == "PENDING"
      );
    },
    is_email_blank() {
      return (
        this.currentUser.selectedOrganizationPublic &&
        this.currentUser.emailRegStatus == "BLANK"
      );
    },
    is_email_confirmed() {
      return (
        this.currentUser.selectedOrganizationPublic &&
        this.currentUser.emailRegStatus == "COMPLETE"
      );
    },
    is_mobile_pending() {
      return (
        this.currentUser.selectedOrganizationPublic &&
        this.currentUser.mobileRegStatus == "PENDING"
      );
    },
    is_mobile_confirmed() {
      return (
        this.currentUser.selectedOrganizationPublic &&
        this.currentUser.mobileRegStatus == "COMPLETE"
      );
    },
    mapCoordinates() {
      if (!this.map) {
        return this.$defaultCoordinates;
      }
      return {
        lat: this.map.getCenter().lat().toFixed(4),
        lng: this.map.getCenter().lng().toFixed(4),
      };
    },
    google: gmapApi,
    header_background() {
      let bg_img = "/assets/media/ripartire/header-process.jpg";
      if (
        window.location.host.includes("darsenaravenna.bipart.it") ||
        window.location.host.includes("stage.comunediravenna.bipart.it")
      )
        bg_img = "/assets/media/ripartire/header-process-dare.png";
      if (
        window.location.host.includes("spendiamolinsieme.bipart.it") ||
        window.location.host.includes("stage.spendiamolinsieme.bipart.it")
      )
        bg_img = "/assets/media/spendiamolinsieme/header-process-si.png";
      return bg_img;

      // return (window.location.host.includes('darsenaravenna.bipart.it') || window.location.host.includes('stage.comunediravenna.bipart.it')) ? '/assets/media/ripartire/header-process-dare.png' : '/assets/media/ripartire/header-process.jpg';
    },
    canFollow() {
      return this.idea && this.idea.versionCurrent && this.$canFollow;
    },
  },

  async created() {
    this.$isLoading(true);
    //this.images = [];
    //this.attaches = [];
    this.lang = i18nService.getActiveLanguage();
    this.defaultlang = i18nService.getActiveLanguage();
    this.process = await this.getProcess(this.process_id);
    this.percentage = this.getPercentage(this.process);
    this.phase = this.getCurrentPhase(this.process, this.phase_id);
    this.module = this.getCurrentModule(this.process, this.module_id);
    this.voteParams = this.getVoteParams(this.module);
    this.moduleContentType = this.module?.contentType;
    

    this.hasProcessManyPhases = this.process.countProcessPhases > 1;
    this.hasPhaseManyModules = this.phase.countProcess2Modules > 1;
    this.hasModuleManyIdeas = this.module.countIdeas > 1;

    if (
      this.module &&
      this.module.processVoteIdForVoteType1 &&
      this.module.processVoteIdForVoteType1 > 0
    ) {
      this.moduleVote1IsOpen = await this.checkVoteIsOpen(
        this.module.processVoteIdForVoteType1
      );
    }

    if (this.module) {
      this.ideaSimpleAssessmentStatuses =
        this.module.ideaSimpleAssessmentStatuses;
      
      this.activeFollowing = this.module.activeFollowing;
      this.activeCommenting = this.module.activeCommenting;
      this.maxVersionsPerContent = this.module.maxVersionsPerContent || 0;
      this.maxCompletionDaysPerVersion =
        this.module.maxCompletionDaysPerVersion || 0;
      this.canAssessDetail = this.module.canAssessDetail;
      this.canAssessMaster = this.module.canAssessMaster;
      this.publishAssessmentsDetail = this.module.publishAssessmentsDetail;
      this.publishAssessmentsMaster = this.module.publishAssessmentsMaster;
      this.publishVotes = this.module.publishVotes ?? false;
      

      // let isEmailConfirmed = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data"))?.emailRegStatus === "COMPLETE" : false;
      
      // this.flagMessage = (this.module.activeMessaging && this.module.canMessage && isEmailConfirmed) ?? false;
      this.flagMessage =
        (this.module.activeMessaging && this.module.canMessage) ?? false;

      if (
        this.publishVotes &&
        this.module &&
        this.module.processVoteIdForVoteType1 &&
        this.module.processVoteIdForVoteType1 > 0
      ) {
        this.publishValueVotes1 = true;
      }

      if (
        this.module &&
        this.module.processVoteIdForVoteType4 &&
        this.module.processVoteIdForVoteType4 > 0
      ) {
        this.processVoteIdForVoteType4 = true;
        this.moduleVote4IsOpen = await this.checkVoteIsOpen(
          this.module.processVoteIdForVoteType4
        );
      }

      var date = new Date();
      date.setDate(date.getDate() + this.maxCompletionDaysPerVersion);
      this.maxCompletionDaysPerVersionDate =
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear();

      this.openDate = this.module.openDate || null;
      this.closeDate = this.module.closeDate || null;

      if (this.openDate) {
        this.openDate = new Date(this.openDate);
        this.moduleDateStr = moment(this.openDate).format("DD/MM/Y");
        //this.moduleDateStr = this.openDate.getDate()  + "/" + (this.openDate.getMonth()+1) + "/" + this.openDate.getFullYear();
      }

      if (this.closeDate) {
        this.closeDate = new Date(this.closeDate);
        this.moduleDateStr += " - " + moment(this.closeDate).format("DD/MM/Y");
        //this.moduleDateStr += " - " + this.closeDate.getDate()  + "/" + (this.closeDate.getMonth()+1) + "/" + this.closeDate.getFullYear();
      }
    }

    this.borderSubtitle =
      this.$t("IDEADETAIL.PUOIDONARE") +
      this.$t("IDEADETAIL.DONAREDA") +
      this.voteParams.borders.min +
      "€ " +
      this.$t("IDEADETAIL.DONAREFINOA") +
      this.voteParams.borders.max +
      "€";

    await this.getCurrentIdea(this.id).then((res) => {
      this.idea = res.data;
      
      this.ideaOgTitle = this.idea.title;
      this.ideaOgLogo = this.getLogo;
      // this.ideaOgDescription = (this.idea.description | this.strippedContent | this.truncate( 200, '...'));
      this.$emit("updateHead");
      // this.pagetitle = this.idea.title;
      // this.metatitle = this.idea.title;

      const agendaIds = this.idea.challenges
        .map((x) => x.agendaId)
        .filter((value, index, array) => array.indexOf(value) === index);

      for (let aId of agendaIds) {
        var endpoint = this.generateUrl(`agendas/${aId}`, true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint).then((res) => {
          this.agendas.push({
            id: res.data.id,
            name: res.data.name || "",
          });
        });
      }

      //  breadcrumb
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
        {
          title: this.process.title,
          route: this.hasProcessManyPhases
            ? "/process-detail/" + this.process_id
            : "disabled",
        },
        // { title: this.process.localizations[0].title, route: "/process-detail/" + this.process_id },
        {
          title: this.phase.title,
          route: this.hasPhaseManyModules
            ? `/process-phase/${this.phase_id}/${this.process_id}`
            : "disabled",
        },
        {
          title: this.module.title,
          route: this.hasModuleManyIdeas
            ? "/idea-list/" +
            this.module_id +
            "/" +
            this.process_id +
            "/" +
            this.phase_id
            : "disabled",
        }, // da fare
        { title: this.idea.title },
      ]);

      
      this.idPublicIdea = this.idea.versions.find(
        (x) => x.versionCurrent == true
      )
        ? this.idea.versions.find((x) => x.versionCurrent == true).id
        : 0;

      if (this.idea.ideaStatus.id == 1 && !this.isAuthor()) {
        if (this.idPublicIdea && this.idPublicIdea > 0) {
          this.$router.push({
            name: "processModuleIdeaDetail",
            params: {
              id: this.idPublicIdea,
              process_id: this.process_id,
              phase_id: this.phase_id,
              module_id: this.module_id,
              phase_status_id: this.phase_status_id,
            },
          });
        } else {
          this.$router.push({ name: "intro" });
        }
      } else {
        // this.idea_voted = res.data.type1VotedByAuthUser;
        this.voto4 =
          res.data.type4VotedByAuthUser > 0
            ? res.data.type4VotedByAuthUser
            : "";
        this.voto4_origin = this.voto4;
        this.marker = {
          position: this.idea.centroid
            ? this.pointToCoordinates(this.idea.centroid)
            : {},
        };

        if (this.idea.centroid) {
          this.myCoordinates = this.pointToCoordinates(this.idea.centroid);
        } else if (!this.idea.centroid && this.process.cities[0]) {
          let cityPoint = this.process.cities[0].centroid;
          this.myCoordinates = this.pointToCoordinates(cityPoint);
        } else if (
          !this.idea.centroid &&
          this.process.cities.length < 1 &&
          localStorage.center
        ) {
          this.myCoordinates = JSON.parse(localStorage.center);
        } else {
          this.$getLocation({})
            .then((coordinates) => {
              this.myCoordinates = coordinates;
            })
            .catch(() => {
              this.myCoordinates = this.$defaultCoordinates;
            });
        }

        if (localStorage.zoom) {
          this.zoom = parseInt(localStorage.zoom);
        }

        this.indexLanguage =
          this.idea.ideaSimpleAssessmentStatus.localizations.findIndex(
            (x) => x.locale == i18nService.getActiveLanguage()
          );
        this.versions = this.isAuthor()
          ? this.idea.versions
          : this.idea.versions.filter((x) => x.ideaStatus.id == 3);
        this.versionsReverse = this.versions.slice().reverse();
        this.idIdeaToVersioning = this.idea.versions.find(
          (x) => x.versionCurrent == true
        )
          ? this.idea.versions.find((x) => x.versionCurrent == true).id
          : 0;
        this.idIdeaDraft = this.idea.versions.find((x) => x.ideaStatus.id == 1)
          ? this.idea.versions.find((x) => x.ideaStatus.id == 1).id
          : 0;
        this.existsUnpubVersionInGroup = this.idea.existsUnpubVersionInGroup;
        this.versionNumber = this.idea.versionNumber;
        this.commentNumber = this.idea.countIdeaComments;
        this.challengesNumber = this.idea.countChallenges;
        this.scenariosNumber = this.idea.scenarios.length;
        this.ideaSimpleAssessments = this.publishAssessmentsDetail
          ? this.idea.ideaSimpleAssessments
          : this.idea.ideaSimpleAssessments.filter(
            (x) => x.assessor.id == this.currentUser.id
          );
        this.hasAssessDetail =
          this.idea.ideaSimpleAssessments.filter(
            (x) => x.assessor.id == this.currentUser.id
          ).length > 0;
        this.hasAssessMaster =
          this.idea &&
          this.idea.assessor &&
          this.idea.assessor.id &&
          this.idea.assessor.id > 0;
        this.ideaAssessmentNumber = this.hasAssessMaster
          ? this.ideaSimpleAssessments.length + 1
          : this.ideaSimpleAssessments.length;
        this.publishAssessmentsMaster =
          this.publishAssessmentsMaster ||
          (this.idea &&
            this.idea.assessor &&
            this.idea.assessor.id &&
            this.idea.assessor.id == this.currentUser.id);
        this.ideaLocalizations = this.idea.localizations ?? [];
        this.displayViewMap =
          this.idea.centroid &&
          this.idea.centroid != null &&
          this.process?.cities?.length > 0;
        this.idea2FollowersCounter = this.idea?.idea2Followers?.length ?? 0;

        //this.setResources();
        this.setLocalizationValue();

        if (this.forced_evaluate) {
          this.tabindexString = "evaluations";
        }
      }
    });
    this.handleIsContactOwner();
    this.$isLoading(false);
  },

  mounted() {
    if (this.$refs.mapRef) {
      this.$refs.mapRef.$mapPromise.then((map) => (this.map = map));
    }
  },

  watch: {
    $route() {
      this.$isLoading(true);
      this.see_tabs = true;
      this.images = [];
      this.attaches = [];
      this.id = this.$route.params.id;
      this.process_id = this.$route.params.process_id;
      this.phase_id = this.$route.params.phase_id;
      this.module_id = this.$route.params.module_id;

      this.getCurrentIdea(this.id).then((res) => {
        this.idea = res.data;
        this.voto4 =
          res.data.type4VotedByAuthUser > 0
            ? res.data.type4VotedByAuthUser
            : "";
        this.voto4_origin = this.voto4;

        const agendaIds = this.idea.challenges
          .map((x) => x.agendaId)
          .filter((value, index, array) => array.indexOf(value) === index);

        for (let aId of agendaIds) {
          var endpoint = this.generateUrl(`agendas/${aId}`, true);
          //generateUrl is done
          ApiService.get(this.$apiResource, endpoint).then((res) => {
            this.agendas.push({
              id: res.data.id,
              name: res.data.name || "",
            });
          });
        }

        this.marker = {
          position: this.idea.centroid
            ? this.pointToCoordinates(this.idea.centroid)
            : {},
        };

        if (this.idea.centroid) {
          this.myCoordinates = this.pointToCoordinates(this.idea.centroid);
        } else if (!this.idea.centroid && this.process.cities[0]) {
          let cityPoint = this.process.cities[0].centroid;
          this.myCoordinates = this.pointToCoordinates(cityPoint);
        } else if (
          !this.idea.centroid &&
          this.process.cities.length < 1 &&
          localStorage.center
        ) {
          this.myCoordinates = JSON.parse(localStorage.center);
        } else {
          this.$getLocation({})
            .then((coordinates) => {
              this.myCoordinates = coordinates;
            })
            .catch(() => {
              this.myCoordinates = this.$defaultCoordinates;
            });
        }

        if (localStorage.zoom) {
          this.zoom = parseInt(localStorage.zoom);
        }

        this.indexLanguage =
          this.idea.ideaSimpleAssessmentStatus.localizations.findIndex(
            (x) => x.locale == i18nService.getActiveLanguage()
          );
        this.versions = this.isAuthor()
          ? this.idea.versions
          : this.idea.versions.filter((x) => x.ideaStatus.id == 3);
        this.versionsReverse = this.versions.slice().reverse();
        this.idIdeaToVersioning = this.idea.versions.find(
          (x) => x.versionCurrent == true
        )
          ? this.idea.versions.find((x) => x.versionCurrent == true).id
          : 0;
        this.idIdeaDraft = this.idea.versions.find((x) => x.ideaStatus.id == 1)
          ? this.idea.versions.find((x) => x.ideaStatus.id == 1).id
          : 0;
        this.existsUnpubVersionInGroup = this.idea.existsUnpubVersionInGroup;
        this.versionNumber = this.idea.versionNumber;
        this.commentNumber = this.idea.countIdeaComments;
        this.challengesNumber = this.idea.countChallenges;
        this.scenariosNumber = this.idea.scenarios.length;
        this.ideaSimpleAssessments = this.publishAssessmentsDetail
          ? this.idea.ideaSimpleAssessments
          : this.idea.ideaSimpleAssessments.filter(
            (x) => x.assessor.id == this.currentUser.id
          );
        this.hasAssessDetail =
          this.idea.ideaSimpleAssessments.filter(
            (x) => x.assessor.id == this.currentUser.id
          ).length > 0;
        this.hasAssessMaster =
          this.idea &&
          this.idea.assessor &&
          this.idea.assessor.id &&
          this.idea.assessor.id > 0;
        this.ideaAssessmentNumber = this.hasAssessMaster
          ? this.ideaSimpleAssessments.length + 1
          : this.ideaSimpleAssessments.length;
        this.publishAssessmentsMaster =
          this.publishAssessmentsMaster ||
          (this.idea &&
            this.idea.assessor &&
            this.idea.assessor.id &&
            this.idea.assessor.id == this.currentUser.id);
        this.ideaLocalizations = this.idea.localizations ?? [];
        this.displayViewMap =
          this.idea.centroid &&
          this.idea.centroid != null &&
          this.process?.cities?.length > 0;
        this.idea2FollowersCounter = this.idea?.idea2Followers?.length ?? 0;

        //this.setResources();
        this.setLocalizationValue();
        this.$isLoading(false);
      });
    },
  },

  methods: {
    forceRenderContactOwner() {
      this.contactOwnerKey += 1;
      
    },
    handleIsContactOwner() {
      this.isContactOwner = this.idea.author.id !== this.currentUser.id;
    },

    getDonationValue(e) {
      
      e.key == "." || e.key == "," || e.key == "e"
        ? (this.donationValue = e.key)
        : (this.donationValue = e.target._value);

      

      if (this.donationValue != null || this.donationValue != "")
        this.checkForbiddenCharacters();
    },

    checkForbiddenCharacters() {
      
      // eslint-disable-next-line no-useless-escape
      if (/[,\.e]/.test(this.donationValue)) {
        this.hasForbiddenCharacters = true;
      } else {
        this.hasForbiddenCharacters = false;
      }
      
    },

    getGradeValue(e) {
      
      e.key == "." || e.key == "," || e.key == "e"
        ? (this.form_evaluationGradeValue = e.key)
        : (this.form_evaluationGradeValue = e.srcElement._value);
    },

    getGradeValueMaster(e) {
      
      e.key == "." || e.key == "," || e.key == "e"
        ? (this.form_evaluationMasterGradeValue = e.key)
        : (this.form_evaluationMasterGradeValue = e.srcElement._value);
    },

    validationAssesmentStatus(inputValue, targetGroup) {
      
      return {
        "is-valid": !inputValue.$invalid && inputValue.$model !== "",
        "is-invalid":
          inputValue.$invalid &&
          inputValue.$dirty &&
          !this["isAssesmentFieldFilled" + targetGroup] &&
          !this["isGradeFieldFilled" + targetGroup],
      };
    },

    validationGradeStatus(inputValue, targetGroup) {
      
      return {
        "is-valid": !inputValue.$invalid && inputValue.$model !== "",
        "is-invalid":
          (inputValue.$invalid &&
            inputValue.$dirty &&
            !this["isAssesmentFieldFilled" + targetGroup] &&
            !this["isGradeFieldFilled" + targetGroup]) ||
          (this["form_evaluation" + targetGroup + "GradeValue"] != "" &&
            inputValue.$invalid &&
            inputValue.$dirty),
      };
    },

    imageZoom(url, event) {
      event.preventDefault();
      
      this.selectedImage = url;
    },

    tabs_toogle: function (value) {
      this.see_tabs = value;
    },

    setLanguage(language) {
      this.lang = language;
      this.setLocalizationValue();
    },

    setLocalizationValue() {
      var localization = this.idea.localizations.find(
        (x) => x.locale == this.lang
      );
      this.ideaTitle = localization?.title;
      this.ideaDescription = localization?.description;
      this.ideaNarration = localization?.narration;
      this.setResources();
    },

    handleDrag() {
      let center = {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      };
      let zoom = this.map.getZoom();

      localStorage.center = JSON.stringify(center);
      localStorage.zoom = zoom;
    },

    toggleInfoWindow: function (marker) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      this.infoWinOpen = !this.infoWinOpen;
    },

    scrollMeToComment(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
      this.tabindexString = "comments";
    },
    scrollMeToEvaluate(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
      this.tabindexString = "evaluations";
    },
    alertCanVersion() {
      if(this.can_access_module("version")) {
        Swal.fire({
          title: this.$t("IDEADETAIL.CANVERSIONTITLE"),
          html: this.$t("IDEADETAIL.CANVERSION"),
          icon: "info",
          confirmButtonClass: "btn btn-primary",
        });
      } else {
        this.alertUserModuleAccessBitmaskVersion(this.moduleContentType);    
      }
    },
    alertHelp() {
      Swal.fire({
        title: this.$t("IDEADETAIL.ALERT2TITLE"),
        html: this.$t("IDEADETAIL.ALERT2"),
        icon: "info",
        confirmButtonClass: "btn btn-primary",
      });
    },
    alertEmptyVote1UserLeft() {
      Swal.fire({
        title: this.$t("IDEADETAIL.ALERTEMPTYVOTE1USERLEFTTITLE"),
        icon: "info",
        html: this.$t("IDEADETAIL.ALERTEMPTYVOTE1USERLEFTHTML"),
        confirmButtonClass: "btn btn-secondary",
      });
    },
    alertVoteIsClosed() {
      Swal.fire({
        title: this.$t("IDEADETAIL.VOTAZIONECHIUSA"),
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },

    alertAddVote() {
      Swal.fire({
        title: this.$t(
          this.$handleContentTypeLabel(this.moduleContentType) +
          "DETAIL.ALERT3TITLE"
        ),
        html: this.$t("IDEADETAIL.ALERT3"),
        icon: "success",
        //confirmButtonClass: "btn btn-secondary"
      });
    },
    alertRemoveVote() {
      Swal.fire({
        title: this.$t("IDEADETAIL.ALERT4TITLE"),
        html: this.$t(
          this.$handleContentTypeLabel(this.moduleContentType) + "DETAIL.ALERT4"
        ),
        icon: "error",
        //confirmButtonClass: "btn btn-secondary"
      });
    },
    alertCrowdfunding(type) {
      //const vote = this.idea.idea2Votes[0].vote;
      
      // Rimozione del sostegno
      if (type == 3) {
        Swal.fire({
          title: this.$t("IDEADETAIL.CIFRARIMOSSA"),
          html: this.$t(
            this.$handleContentTypeLabel(this.moduleContentType) +
            "DETAIL.SOSTIENIALTREIDEE"
          ),
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }
      // Aggiunta del sostegno
      else if (type == 1) {
        Swal.fire({
          title: this.$t(
            this.$handleContentTypeLabel(this.moduleContentType) +
            "DETAIL.ALERT5TITLE"
          ),
          html: this.$t(
            this.$handleContentTypeLabel(this.moduleContentType) +
            "DETAIL.ALERT5"
          ),
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }
      // Modifica del sostegno
      else if (type == 2) {
        Swal.fire({
          title: this.$t(
            this.$handleContentTypeLabel(this.moduleContentType) +
            "DETAIL.GRAZIESOSTEGNOANCORA"
          ),
          html:
            "<b>" +
            this.$t("IDEADETAIL.CIFRAMODIFICATA") +
            "</b>" +
            this.borderSubtitle,
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },
    deleteIdea(e) {
      e.preventDefault();

      if(this.can_access_module("delete")) {
        Swal.fire({
          title: this.$t(
            this.$handleContentTypeLabel(this.moduleContentType) +
            "DETAIL.ALERT8TITLE"
          ),
          text: this.$t("IDEADETAIL.ALERT8"),
          type: "danger",
          showCancelButton: true,
          confirmButtonText: this.$t("IDEADETAIL.ALERT8B1"),
          cancelButtonText: this.$t("IDEADETAIL.ALERT8B2"),
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            // Cancello l'idea'
            return new Promise((resolve, reject) => {
              ApiService.delete(this.$apiResourceOpen + "/ideas/" + this.idea.id)
                .then(() => {
                  Swal.fire(
                    this.$t(
                      this.$handleContentTypeLabel(this.moduleContentType) +
                      "DETAIL.ALERT9TITLE"
                    ),
                    this.$t(
                      this.$handleContentTypeLabel(this.moduleContentType) +
                      "DETAIL.ALERT9"
                    ),
                    "success"
                  );

                  //Torno al modulo
                  this.$router.push({
                    name: "processModuleIdeaList",
                    params: {
                      process_id: this.process_id,
                      phase_id: this.phase_id,
                      id: this.module_id,
                    },
                  });

                  resolve(1);
                })
                .catch(() => {
                  Swal.fire(this.$t("IDEADETAIL.ALERT10TITLE"), "", "info");
                  reject(0);
                });
            });
          } else {
            Swal.fire(this.$t("IDEADETAIL.ALERT10TITLE"), "", "info");
          }
        });
      } else {
        this.alertUserModuleAccessBitmaskDelete(this.moduleContentType);
      }
    },

    setResources() {
      this.images = [];
      this.attaches = [];
      let documentSet = [];

      for (let r of this.idea.resources) {
        switch (r.resourceType.id) {
          case 1: // Documenti
          case 2:
            documentSet = this.attaches;
            break;
          case 3: // Immagini
            documentSet = this.images;
            break;
          case 5: // Video
            // documentSet = this.video;
            break;
          case 6: // YoutubeLink
          case 10: // Link to external service
            this.youtubeLink =
              "//www.youtube.com/embed/" + this.getEmbededUrl(r.uri);
            break;
        }

        if (r.resourceType.id != 10) {
          documentSet.push({
            id: r.id,
            name:
              r.localizations != null
                ? r.localizations.find((x) => x.locale == this.lang)?.title
                : r.originalFilename,
            uri: r.uri,
          });
        }
      }
    },

    async incrementFollower(e) {
      if (e != null) {
        e.preventDefault();
      }
      let endpointCanFollow = await this.$endpointCanFollow(this.module_id);

      if (endpointCanFollow) {
        if (this.can_access_module("follow")) {
          this.followerAdd(this.id, this.currentUser.id);
          this.idea.countFollowers += 1;
          this.idea.followedByAuthUser = true;
          this.idea2FollowersCounter += 1;
          this.followeSet = false;
          this.tabindexString = "followers";
          this.alertAddFollower(this.moduleContentType);
        } else {
          this.alertUserModuleAccessBitmaskFollow();
        }
      } else {
        this.alertCanNotFollow();
      }
    },

    async decrementFollower(e) {
      if (e != null) {
        e.preventDefault();
      }
      let endpointCanFollow = await this.$endpointCanFollow(this.module_id);

      if (endpointCanFollow) {
        if (this.can_access_module("follow")) {
          this.followerDel(this.id, this.currentUser.id);
          this.idea.countFollowers -= 1;
          this.idea.followedByAuthUser = false;
          this.idea2FollowersCounter -= 1;
          this.followeSet = false;
          this.tabindexString = "followers";
          this.alertRemoveFollower(this.moduleContentType);
        } else {
          this.alertUserModuleAccessBitmaskFollow();
        }
      } else {
        this.alertCanNotFollow();
      }
    },

    async advIncFollower(e) {
      e.preventDefault();

      if (this.checkUserStatus()) {
        await this.incrementFollower(e);
        // let result = await this.incrementFollower(e);
        // if (result == 1) {
        //   this.alertAddVote();
        // }
      }
    },

    async advDecFollower(e) {
      e.preventDefault();

      if (this.checkUserStatus()) {
        await this.decrementFollower(e);
        // let result = await this.decrementFollower(e);
        // if (result == 1) {
        //   this.alertAddVote();
        // }
      }
    },

    async addPreferenza() {
      // controllo se il voto è aperto
      let voteIsOpen = await this.checkVoteIsOpen(
        this.module.processVoteIdForVoteType1
      );
      

      // Blocco il voto
      if (!voteIsOpen) {
        this.alertVoteIsClosed();

        // Invalido il processo e ricarico la pagina
        let current_process = JSON.parse(
          window.localStorage.getItem("current_process_data")
        );
        current_process.is_active = false;
        window.localStorage.setItem(
          "current_process_data",
          JSON.stringify(current_process)
        );
        window.location.reload();

        return -1;
      }

      let voteData = {
        idea: {
          id: this.idea.id,
        },
        voter: {
          id: this.currentUser.id,
        },
        processVoteId: this.module.processVoteIdForVoteType1,
        processVote: {
          id: this.module.processVoteIdForVoteType1,
        },
        voteType: {
          id: 1,
        },
        vote: 1.0,
      };

      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("/idea-votes", false, false);
        //generateUrl is done
        ApiService.post(this.$apiResourceOpen + endpoint, voteData)
          .then(() => {
            this.idea.valueVotes1 += 1;
            this.idea.type1VotedByAuthUser = true;
            // this.idea_voted = true;
            this.idea.leftValueVotes1ForP2MIdeasByAuthUser -= 1;
            // location.reload();
            this.$refs["userVotesPanel"].getVotes();
            resolve(1);
          })
          .catch(() => {
            reject(0);
          });
      });
    },
    async delPreferenza() {
      // controllo se il voto è aperto
      let voteIsOpen = await this.checkVoteIsOpen(
        this.module.processVoteIdForVoteType1
      );
      

      // Blocco il voto
      if (!voteIsOpen) {
        this.alertVoteIsClosed();

        // Invalido il processo e ricarico la pagina
        let current_process = JSON.parse(
          window.localStorage.getItem("current_process_data")
        );
        current_process.is_active = false;
        window.localStorage.setItem(
          "current_process_data",
          JSON.stringify(current_process)
        );
        window.location.reload();

        return -1;
      }

      let voteData = {
        idea: {
          id: this.idea.id,
        },
        voter: {
          id: this.currentUser.id,
        },
        processVoteId: this.module.processVoteIdForVoteType1,
        processVote: {
          id: this.module.processVoteIdForVoteType1,
        },
        voteType: {
          id: 1,
        },
        vote: 1.0,
        applied: false,
      };

      return new Promise((resolve, reject) => {
        ApiService.put(this.$apiResourceOpen + "/idea-votes", voteData)
          .then(() => {
            this.idea.valueVotes1 -= 1;
            this.idea.type1VotedByAuthUser = "";
            // this.idea_voted = false;
            this.idea.leftValueVotes1ForP2MIdeasByAuthUser += 1;
            // location.reload();
            this.$refs["userVotesPanel"].getVotes();
            resolve(1);
          })
          .catch(() => {
            reject(0);
          });
      });
    },

    async simpleAddPreferenza(e) {
      e.preventDefault();

      await this.updateLocalIdeaData();

      // Controllo se l'utente ha preferenze da assegnare
      if (this.idea.leftValueVotes1ForP2MIdeasByAuthUser > 0) {
        let result = await this.addPreferenza();
        if (result == 1) {
          this.alertAddVote();
        }
      } else {
        this.alertEmptyVote1UserLeft();
      }
    },
    async simpleDelPreferenza(e) {
      e.preventDefault();

      let result = await this.delPreferenza();
      if (result == 1) {
        this.alertRemoveVote();
      }
    },

    //--
    async advAddPreferenza(e) {
      e.preventDefault();

      await this.updateLocalIdeaData();

      if (this.checkUserStatus()) {
        // Controllo se l'utente ha preferenze da assegnare
        if (
          this.idea.leftValueVotes1ForP2MIdeasByAuthUser > 0 &&
          !this.is_anonymous
        ) {
          let result = await this.addPreferenza();
          if (result == 1) {
            this.alertAddVote();
            this.addPreferenceSet = false;
          }
        } else {
          this.alertEmptyVote1UserLeft();
        }
      } else {
        this.addPreferenceSet = true;
        
      }
    },
    async advDelPreferenza(e) {
      e.preventDefault();

      this.delPreferenceSet = true;
      if (this.checkUserStatus()) {
        let result = await this.delPreferenza();
        if (result == 1) {
          this.alertRemoveVote();
          this.delPreferenceSet = false;
        }
      }
    },

    async checkVoteIsOpen(voteModuleId) {
      return await new Promise((resolve, reject) => {
        let endpoint = this.generateUrl(
          `/process-votes/${voteModuleId}/vote-is-open`,
          false,
          false
        );
        ApiService.get(this.$apiResourceOpen + endpoint)
          .then((res) => {            
            const open = res.data.open || false;
            resolve(open);
          })
          .catch(() => {
            reject(0);
          });
      });
    },

    async addWallet() {
      // Controllo se il voto è aperto
      let voteIsOpen = await this.checkVoteIsOpen(
        this.module.processVoteIdForVoteType4
      );
      

      // Blocco il voto
      if (!voteIsOpen) {
        this.alertVoteIsClosed();
        this.voto4 = this.voto4_origin;

        // Invalido il processo e ricarico la pagina
        
        let current_process = JSON.parse(
          window.localStorage.getItem("current_process_data")
        );
        current_process.is_active = false;
        window.localStorage.setItem(
          "current_process_data",
          JSON.stringify(current_process)
        );
        
        window.location.reload();
        

        return -1;
      }

      // validazione dell'importo inserito
      if (!isNaN(parseFloat(this.voto4)) && parseFloat(this.voto4) !== 0) {
        let subtitle =
          this.$t("IDEADETAIL.PUOIDONARE") +
          this.$t("IDEADETAIL.DONAREDA") +
          this.voteParams.borders.min +
          "€ " +
          this.$t("IDEADETAIL.DONAREFINOA") +
          this.voteParams.borders.max +
          "€";
        if (this.hasForbiddenCharacters) {
          Swal.fire(this.$t("IDEADETAIL.ALERT16"), "", "error");
          return -1;
        } else if (
          typeof this.voteParams.borders.min === "number" &&
          parseFloat(this.voto4) < this.voteParams.borders.min
        ) {
          // Stai donando una cifra troppo bassa
          this.voto4 = this.voto4_origin;
          Swal.fire(this.$t("IDEADETAIL.CIFRABASSA"), subtitle, "error");
          return -1;
        } else if (
          typeof this.voteParams.borders.max === "number" &&
          parseFloat(this.voto4) > this.voteParams.borders.max
        ) {
          // Stai donando una cifra troppo alta
          
          
          this.voto4 = this.voto4_origin;
          Swal.fire(this.$t("IDEADETAIL.CIFRAALTA"), subtitle, "error");
          return -1;
        } else if (
          parseFloat(this.voto4) >
          this.idea.leftValueVotes4ForP2MIdeasByAuthUser
        ) {
          // Stai donando una cifra che non hai a disposizione
          this.voto4 = this.voto4_origin;
          let desc =
            this.$t("IDEADETAIL.CIFRADELBUDGET") +
            " " +
            this.idea.leftValueVotes4ForP2MIdeasByAuthUser +
            "€<br />" +
            this.$t(
              this.$handleContentTypeLabel(this.moduleContentType) +
              "DETAIL.VALUTARIDUZIONEDONAZIONI"
            );
          Swal.fire(
            this.$t("IDEADETAIL.CIFRASUPERIOREALBUDGET"),
            desc,
            "error"
          );
          return -1;
        }
      } else if (
        (isNaN(parseFloat(this.voto4)) || parseFloat(this.voto4) === 0) &&
        !this.idea.type4VotedByAuthUser
      ) {
        // Indica la cifra che vuoi donare
        Swal.fire(this.$t("IDEADETAIL.INDICACIFRA"), "", "error");
        return -1;
      }

      // Creazione dei dati per la chiamata di voto
      let voteData = {
        idea: {
          id: this.idea.id,
        },
        voter: {
          id: this.currentUser.id,
        },
        processVoteId: this.module.processVoteIdForVoteType4,
        processVote: {
          id: this.module.processVoteIdForVoteType4,
        },
        voteType: {
          id: 4,
        },
        vote: !isNaN(parseFloat(this.voto4)) ? parseFloat(this.voto4) : 0.0,
      };

      // Procedo con il voto
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("/idea-votes", false, false);
        //generateUrl is done
        ApiService.post(this.$apiResourceOpen + endpoint, voteData)
          .then((res) => {
            

            // Gestione del messaggio sulla modifica del Wallet
            let alertToShow = 1; // Inserimento donazione
            if (!res.data.applied)
              // Rimozione donazione
              alertToShow = 3;
            if (res.data.applied && this.idea.type4VotedByAuthUser > 0)
              // Modifica donazione
              alertToShow = 2;

            this.idea.type4VotedByAuthUser = res.data.applied
              ? parseFloat(this.voto4)
              : "";
            // (!isNaN(parseFloat(this.voto4)) && parseFloat(this.voto4) > 0.00)
            //   ? parseFloat(this.voto4)
            //   : "";
            this.idea.valueVotes4 = res.data.idea.valueVotes4;
            this.voto4_origin = this.idea.valueVotes4;
            this.idea.countSupporters = res.data.idea.countSupporters;
            // this.idea_voted = (res.data.applied || this.idea.valueVotes1 > 0);

            
            resolve(alertToShow);
          })
          .catch(() => {
            reject(0);
          });
      });
    },

    async simpleAddWallet(e) {
      e.preventDefault();
      let alertType = await this.addWallet();
      if (alertType > 0) this.alertCrowdfunding(alertType);
    },

    async advAddWallet(e) {
      e.preventDefault();
      this.addWalletSet = true;

      if (this.checkUserStatus()) {
        let alertType = await this.addWallet();
        if (alertType > 0) this.alertCrowdfunding(alertType);
      }
    },

    async manageVoteByFlag() {
      // Gestione del voto con preferenza
      // Se ho memorizzato di mettere la preferenza e l'idea non ha ancora la preferenza dell'utente
      await this.updateLocalIdeaData();
      
      if (this.addPreferenceSet && !this.idea.type1VotedByAuthUser) {
        // Controllo se l'utente ha preferenze da assegnare
        if (
          this.idea.leftValueVotes1ForP2MIdeasByAuthUser > 0 &&
          !this.is_anonymous
        ) {
          
          let result = await this.addPreferenza();
          if (result == 1) {
            
            this.alertAddVote();
            this.addPreferenceSet = false;
          }
        } else {
          this.alertEmptyVote1UserLeft();
        }
      }
      // Gestione del voto con budget
      else if (this.addWalletSet) {
        

        let alertType = await this.addWallet();
        if (alertType > 0) {
          this.alertCrowdfunding(alertType);
        } else {
          
          this.alertAddVote();
        }
      }
    },

    addComment() {
      let commentData = {
        ideaId: this.id,
        comment: this.new_comment,
      };

      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("/idea-comments", true, false);
        //generateUrl is done
        ApiService.post(this.$apiResourceOpen + endpoint, commentData)
          .then((res) => {
            resolve(res.data);
          })
          .catch(() => {
            reject(0);
          });
      });
    },

    addEvaluate() {
      this.$isLoading(true);
      let evaluationData = {
        ideaId: this.id,
        assessment: this.$v.form_evaluation.assessment.$model,
        grade: this.$v.form_evaluation.grade.$model,
        ideaSimpleAssessmentStatus: {
          id: this.$v.form_evaluation.ideaSimpleAssessmentStatusId.$model,
        },
      };

      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl(
          "/idea-simple-assessments",
          false,
          false
        );
        //generateUrl is done
        ApiService.post(this.$apiResourceOpen + endpoint, evaluationData)
          .then((res) => {
            this.$isLoading(false);
            resolve(res.data);
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },

    addEvaluateMaster() {
      this.$isLoading(true);
      let evaluationData = {
        grade: this.$v.form_evaluation_master.grade.$model,
        assessor: {
          id: this.currentUser.id,
        },
        ideaSimpleAssessmentStatus: {
          id: this.$v.form_evaluation_master.ideaSimpleAssessmentStatusId
            .$model,
        },
      };

      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("/languages/flat", false, false);
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen + endpoint)
          .then((res) => {
            var evaluationDataLocalizations = [];

            for (var i = 0; i < res.data.length; i++) {
              evaluationDataLocalizations.push({
                locale: res.data[i].locale,
                assessment: this.$v.form_evaluation_master.assessment.$model,
                assessmentNative:
                  res.data[i].locale == i18nService.getActiveLanguage(),
              });
            }

            evaluationData.localizations = evaluationDataLocalizations;

            endpoint = this.generateUrl(
              "/ideas/" + this.id + "/idea-simple-assessment",
              false,
              false
            );
            //generateUrl is done
            ApiService.put(this.$apiResourceOpen + endpoint, evaluationData)
              .then(() => {
                this.getCurrentIdea(this.id).then((res3) => {
                  this.idea = res3.data;
                  this.voto4 =
                    res3.data.type4VotedByAuthUser > 0
                      ? res3.data.type4VotedByAuthUser
                      : "";
                  this.voto4_origin = this.voto4;

                  const agendaIds = this.idea.challenges
                    .map((x) => x.agendaId)
                    .filter(
                      (value, index, array) => array.indexOf(value) === index
                    );

                  for (let aId of agendaIds) {
                    var endpoint = this.generateUrl(`agendas/${aId}`, true);
                    //generateUrl is done
                    ApiService.get(this.$apiResource, endpoint).then((res) => {
                      this.agendas.push({
                        id: res.data.id,
                        name: res.data.name || "",
                      });
                    });
                  }

                  this.marker = {
                    position: this.idea.centroid
                      ? this.pointToCoordinates(this.idea.centroid)
                      : {},
                  };

                  if (this.idea.centroid) {
                    this.myCoordinates = this.pointToCoordinates(
                      this.idea.centroid
                    );
                  } else if (!this.idea.centroid && this.process.cities[0]) {
                    let cityPoint = this.process.cities[0].centroid;
                    this.myCoordinates = this.pointToCoordinates(cityPoint);
                  } else if (
                    !this.idea.centroid &&
                    this.process.cities.length < 1 &&
                    localStorage.center
                  ) {
                    this.myCoordinates = JSON.parse(localStorage.center);
                  } else {
                    this.$getLocation({})
                      .then((coordinates) => {
                        this.myCoordinates = coordinates;
                      })
                      .catch(() => {
                        this.myCoordinates = this.$defaultCoordinates;
                      });
                  }

                  if (localStorage.zoom) {
                    this.zoom = parseInt(localStorage.zoom);
                  }

                  this.indexLanguage =
                    this.idea.ideaSimpleAssessmentStatus.localizations.findIndex(
                      (x) => x.locale == i18nService.getActiveLanguage()
                    );
                  this.versions = this.isAuthor()
                    ? this.idea.versions
                    : this.idea.versions.filter((x) => x.ideaStatus.id == 3);
                  this.versionsReverse = this.versions.slice().reverse();
                  this.idIdeaToVersioning = this.idea.versions.find(
                    (x) => x.versionCurrent == true
                  )
                    ? this.idea.versions.find((x) => x.versionCurrent == true)
                      .id
                    : 0;
                  this.idIdeaDraft = this.idea.versions.find(
                    (x) => x.ideaStatus.id == 1
                  )
                    ? this.idea.versions.find((x) => x.ideaStatus.id == 1).id
                    : 0;
                  this.existsUnpubVersionInGroup =
                    this.idea.existsUnpubVersionInGroup;
                  this.versionNumber = this.idea.versionNumber;
                  this.commentNumber = this.idea.countIdeaComments;
                  this.challengesNumber = this.idea.countChallenges;
                  this.scenariosNumber = this.idea.scenarios.length;
                  this.ideaSimpleAssessments = this.publishAssessmentsDetail
                    ? this.idea.ideaSimpleAssessments
                    : this.idea.ideaSimpleAssessments.filter(
                      (x) => x.assessor.id == this.currentUser.id
                    );
                  this.hasAssessDetail =
                    this.idea.ideaSimpleAssessments.filter(
                      (x) => x.assessor.id == this.currentUser.id
                    ).length > 0;
                  this.hasAssessMaster =
                    this.idea &&
                    this.idea.assessor &&
                    this.idea.assessor.id &&
                    this.idea.assessor.id > 0;
                  this.ideaAssessmentNumber = this.hasAssessMaster
                    ? this.ideaSimpleAssessments.length + 1
                    : this.ideaSimpleAssessments.length;
                  this.publishAssessmentsMaster =
                    this.publishAssessmentsMaster ||
                    (this.idea &&
                      this.idea.assessor &&
                      this.idea.assessor.id &&
                      this.idea.assessor.id == this.currentUser.id);
                  this.ideaLocalizations = this.idea.localizations ?? [];

                  //this.setResources();
                  this.setLocalizationValue();
                  this.$isLoading(false);
                  resolve(res3.data);
                });
              })
              .catch(() => {
                this.$isLoading(false);
                reject(0);
              });
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },

    async submitComment(e) {
      e.preventDefault();

      if (this.can_access_module("comment")) {
        // Verify comment not empty
        if (this.new_comment.length > 0) {
          let comment = await this.addComment();
          this.idea.ideaComments.push(comment);
          this.commentNumber = this.commentNumber + 1;
          this.new_comment = ""; // Reset form
          this.alertCommentSended();
        } else {
          this.alertCommentEmpty();
        }
      } else {
        this.alertUserModuleAccessBitmaskComment();
      }
    },

    async submitEvaluateMasterConfirm(e) {
      e.preventDefault();
      Swal.fire({
        title: this.$t("IDEADETAIL.ALERT15TITLE"),
        text: this.$t("IDEADETAIL.ALERT15"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("IDEADETAIL.ALERT15B1"),
        cancelButtonText: this.$t("IDEADETAIL.ALERT15B2"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.value) {
          await this.addEvaluateMaster();
          //this.ideaAssessmentNumber = this.ideaAssessmentNumber + 1;
          
          this.hasAssessMaster = true;

          Swal.fire({
            title: "",
            text: this.$t("IDEADETAIL.VALUTAZIONEINSERITA"),
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });

          // Reset form
          this.$v.form_evaluation_master.assessment.$model = "";
          this.$v.form_evaluation_master.grade.$model = "";
          this.$v.form_evaluation_master.ideaSimpleAssessmentStatusId.$model = 1;
        }
      });
    },

    async submitEvaluateMaster(e) {
      e.preventDefault();

      if (this.can_access_module("assessFinal")) {
        // Effettua la validazione
        this.$v.form_evaluation_master.$touch();

        // Alert in caso di errori e blocco del salvataggio
        // eslint-disable-next-line no-useless-escape
        if (/[,e\.]/.test(this.$v.form_evaluation_master.grade.$model) ||
          this.form_evaluationMasterGradeValue == "." ||
          this.form_evaluationMasterGradeValue == "," ||
          this.form_evaluationMasterGradeValue == "e"
        ) {
          Swal.fire({
            title: this.$t("IDEADETAIL.ALERT16TITLE"),
            text: this.$t("IDEADETAIL.ALERT16"),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        } else if (
          this.$v.form_evaluation_master.grade.$invalid &&
          this.$v.form_evaluation_master.grade.$model.length > 6
        ) {
          Swal.fire({
            title: this.$t("IDEADETAIL.ALERT17TITLE"),
            text: this.$t("IDEADETAIL.ALERT17"),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        } else if (
          this.$v.form_evaluation_master.grade.$invalid &&
          this.$v.form_evaluation_master.ideaSimpleAssessmentStatusId.$invalid
        ) {
          Swal.fire({
            title: this.$t("IDEADETAIL.ALERT1TITLE"),
            text: this.$t(
              this.$handleContentTypeLabel(this.moduleContentType) +
              "DETAIL.ALERT1"
            ),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        } else if (this.hasAssessMaster) {
          Swal.fire({
            title: this.$t("IDEADETAIL.ALERT14TITLE"),
            text: this.$t(
              this.$handleContentTypeLabel(this.moduleContentType) +
              "DETAIL.ALERT14"
            ),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });

          // Reset form
          this.$v.form_evaluation_master.assessment.$model = "";
          this.$v.form_evaluation_master.grade.$model = "";
          this.$v.form_evaluation_master.ideaSimpleAssessmentStatusId.$model = 1;
        } else {
          this.submitEvaluateMasterConfirm(e);
        }
      } else {
        this.alertUserModuleAccess();
      }
    },

    async submitEvaluate(e) {
      e.preventDefault();
      if (this.can_access_module("assessDetail")) {
        // Effettua la validazione
        this.$v.form_evaluation.$touch();

        // Alert in caso di errori e blocco del salvataggio
        // eslint-disable-next-line no-useless-escape
        if (/[e,\.]/.test(this.$v.form_evaluation.grade.$model) ||
          this.form_evaluationGradeValue == "." ||
          this.form_evaluationGradeValue == "," ||
          this.form_evaluationGradeValue == "e"
        ) {
          Swal.fire({
            title: this.$t("IDEADETAIL.ALERT16TITLE"),
            text: this.$t("IDEADETAIL.ALERT16"),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        } else if (
          this.$v.form_evaluation.grade.$invalid &&
          this.$v.form_evaluation.grade.$model.length > 6
        ) {
          Swal.fire({
            title: this.$t("IDEADETAIL.ALERT17TITLE"),
            text: this.$t("IDEADETAIL.ALERT17"),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        } else if (
          this.$v.form_evaluation.grade.$invalid &&
          this.$v.form_evaluation.ideaSimpleAssessmentStatusId.$invalid
        ) {
          Swal.fire({
            title: this.$t("IDEADETAIL.ALERT1TITLE"),
            text: this.$t(
              this.$handleContentTypeLabel(this.moduleContentType) +
              "DETAIL.ALERT1"
            ),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        } else if (this.hasAssessDetail) {
          Swal.fire({
            title: this.$t("IDEADETAIL.ALERT13TITLE"),
            text: this.$t(
              this.$handleContentTypeLabel(this.moduleContentType) +
              "DETAIL.ALERT13"
            ),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });

          // Reset form
          this.$v.form_evaluation.assessment.$model = "";
          this.$v.form_evaluation.grade.$model = "";
          this.$v.form_evaluation.ideaSimpleAssessmentStatusId.$model = 1;
        } else {
          this.submitEvaluateConfirm(e);
        }
      } else {
        this.alertUserModuleAccess();
      }
    },

    async submitEvaluateConfirm(e) {
      e.preventDefault();

      Swal.fire({
        title: this.$t("IDEADETAIL.ALERT15TITLE"),
        text: this.$t("IDEADETAIL.ALERT15"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("IDEADETAIL.ALERT15B1"),
        cancelButtonText: this.$t("IDEADETAIL.ALERT15B2"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.value) {
          let evaluation = await this.addEvaluate();
          this.ideaSimpleAssessments.push(evaluation);
          this.ideaAssessmentNumber = this.ideaAssessmentNumber + 1;
          this.hasAssessDetail = true;

          Swal.fire({
            title: "",
            text: this.$t("IDEADETAIL.VALUTAZIONEINSERITA"),
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });

          // Reset form
          this.$v.form_evaluation.assessment.$model = "";
          this.$v.form_evaluation.grade.$model = "";
          this.$v.form_evaluation.ideaSimpleAssessmentStatusId.$model = 1;
        }
      });
    },

    getCommentDelay(c) {
      return moment(c.createdDate).fromNow();
    },

    deleteComment(commentId) {
      Swal.fire({
        title: this.$t("IDEADETAIL.ALERT11TITLE"),
        text: this.$t("IDEADETAIL.ALERT11"),
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t("IDEADETAIL.ALERT8B1"),
        cancelButtonText: this.$t("IDEADETAIL.ALERT8B2"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          // Cancello l'idea'
          return new Promise((resolve, reject) => {
            ApiService.delete(
              this.$apiResourceOpen + "/idea-comments/" + commentId
            )
              .then(() => {                
                for (let i = 0; i < this.idea.ideaComments.length; i++) {
                  let obj = this.idea.ideaComments[i];

                  if (commentId === obj.id) {
                    this.idea.ideaComments.splice(i, 1);
                  }
                }
                this.commentNumber = this.commentNumber - 1;
              })
              .catch(() => {
                Swal.fire({
                  title: "",
                  text: this.$t("IDEADETAIL.ALERT12TITLE"),
                  icon: "error",
                  confirmButtonClass: "btn btn-secondary",
                });
                reject(0);
              });
          });
        } else {
          Swal.fire(this.$t("IDEADETAIL.ALERT10TITLE"), "", "info");
        }
      });
    },

    //ROLES
    canComment() {
      return (
        this.process.enabled &&
        this.idea &&
        this.idea.versionCurrent &&
        this.module &&
        this.module.canComment
      );
    },
    canAssessMasterF() {
      return (
        this.idea &&
        this.idea.versionCurrent == true &&
        this.canAssessMaster &&
        this.currentUser &&
        this.currentUser.authorities.includes("ASSESSOR")
      );
    },
    canAssessDetailF() {
      return (
        this.idea &&
        this.idea.versionCurrent == true &&
        this.canAssessDetail &&
        this.currentUser &&
        this.currentUser.authorities.includes("EXPERT")
      );
    },
    seeEvaluateTab() {
      return (
        this.idea &&
        this.idea.ideaStatus.id == 3 &&
        (((this.publishAssessmentsDetail || this.publishAssessmentsMaster) &&
          ((this.ideaSimpleAssessments &&
            this.ideaSimpleAssessments.length > 0) ||
            (this.idea &&
              this.idea.ideaSimpleAssessmentStatus &&
              this.idea.assessor))) ||
          this.canAssessMasterF() ||
          this.canAssessDetailF())
      );
    },
    seeEvaluateButton() {
      return (
        this.idea &&
        this.idea.ideaStatus.id == 3 &&
        (this.canAssessMasterF() || this.canAssessDetailF())
      );
    },
    seeAssessMaster() {
      return (
        this.idea &&
        this.idea.ideaStatus.id == 3 &&
        this.idea.ideaSimpleAssessmentStatus &&
        this.idea.ideaSimpleAssessmentStatus.id > 1 &&
        (this.publishAssessmentsMaster || this.canAssessMasterF())
      );
    },
    /*canEvaluate(see = false) {
      if(this.idea && (this.idea.versionCurrent == true || see)) {
        if (this.phase.open && (this.currentUser.authorities.includes('ASSESSOR') || this.currentUser.authorities.includes('EXPERT'))) {
          return true;
        }

        if (!this.phase.open && (this.currentUser.authorities.includes('ASSESSOR'))) {
          return true;
        }
      }
      return false
    },*/
    canVote() {
      if (this.idea && this.idea.versionCurrent == true) {
        let modulesWithVotes = [];
        for (let i = 0; i < this.phase.processVotes.length; i++) {
          if (
            this.phase.processVotes[i].open &&
            !modulesWithVotes.includes(
              this.phase.processVotes[i].process2ModuleId
            )
          ) {
            modulesWithVotes.push(this.phase.processVotes[i].process2ModuleId);
          }
        }

        if (
          this.phase.phaseStatus.id == 2 &&
          this.phase.open &&
          this.phase.processVotes.length > 0 &&
          modulesWithVotes.includes(this.module.id)
        ) {
          return true;
        }
      }
      return false;
    },
    canDelete() {
      if (this.idea.ideaStatus.id == 1 || !this.activeVersioning()) {
        let modulesWithVotes = [];
        for (let i = 0; i < this.phase.processVotes.length; i++) {
          if (
            this.phase.processVotes[i].open &&
            !modulesWithVotes.includes(
              this.phase.processVotes[i].process2ModuleId
            )
          ) {
            modulesWithVotes.push(this.phase.processVotes[i].process2ModuleId);
          }
        }

        if (
          this.phase.phaseStatus.id == 2 &&
          this.idea.author.id == this.currentUser.id &&
          this.phase.processVotes.length > 0 &&
          modulesWithVotes.includes(this.module.id)
        ) {
          return true;
        }

        if (
          this.phase.phaseStatus.id == 2 &&
          this.idea.author.id == this.currentUser.id
        ) {
          return true;
        }
      }

      return false;
    },
    isAuthor() {
      if (this.idea && this.currentUser) {
        return this.idea.author.id == this.currentUser.id;
      }

      return false;
    },
    activeVersioning() {
      return this.module && this.module.activeVersioning;
    },
    canVersioning() {
      if (
        this.isAuthor() &&
        !this.existsUnpubVersionInGroup &&
        this.idea.ideaStatus.id == 3 &&
        this.module &&
        this.module.canVersion
      ) {
        return true;
      }
      return false;
    },
    openVersioning() {
      if(this.can_access_module("version")) {
        this.$router.push({
            name: "processModuleIdeaVersioning",
            params: {
              id: this.idIdeaToVersioning,
              process_id: this.process_id,
              phase_id: this.phase_id,
              module_id: this.module_id,
            },
          });
      } else {
        this.alertUserModuleAccessBitmaskVersion(this.moduleContentType);
      }
    },
    canModify() {
      if (
        (this.idea.ideaStatus.id == 1 &&
          this.module &&
          this.module.canVersion) ||
        !this.activeVersioning()
      ) {
        let modulesWithVotes = [];
        for (let i = 0; i < this.phase.processVotes.length; i++) {
          if (
            this.phase.processVotes[i].open &&
            !modulesWithVotes.includes(
              this.phase.processVotes[i].process2ModuleId
            )
          ) {
            modulesWithVotes.push(this.phase.processVotes[i].process2ModuleId);
          }
        }

        if (
          this.phase.phaseStatus.id == 2 &&
          this.idea.author.id == this.currentUser.id &&
          this.phase.processVotes.length > 0 &&
          modulesWithVotes.includes(this.module.id)
        ) {
          return true;
        }

        if (
          this.phase.phaseStatus.id == 2 &&
          this.idea.author.id == this.currentUser.id
        ) {
          return true;
        }
      }

      return false;
    },
    editIdea(id) {
      if(this.can_access_module("update")) {
        this.$router.push({
            name: "processModuleIdeaEdit",
            params: {
              id: id,
              process_id: this.process_id,
              phase_id: this.phase_id,
              module_id: this.module_id,
            },
          });
      } else {
        this.alertUserModuleAccessBitmaskUpdate(this.moduleContentType);
      }
    },

    //MANAGE MATRIX LIGHT
    can_access_module(action = "") {
      return this.$canAccessModule(this.module, action);
    },

    async updateLocalIdeaData() {
      await this.getCurrentIdea(this.id).then((res) => {
        this.idea = res.data;
        this.voto4 =
          res.data.type4VotedByAuthUser > 0
            ? res.data.type4VotedByAuthUser
            : "";
        this.voto4_origin = this.voto4;

        const agendaIds = this.idea.challenges
          .map((x) => x.agendaId)
          .filter((value, index, array) => array.indexOf(value) === index);

        for (let aId of agendaIds) {
          var endpoint = this.generateUrl(`agendas/${aId}`, true);
          //generateUrl is done
          ApiService.get(this.$apiResource, endpoint).then((res) => {
            this.agendas.push({
              id: res.data.id,
              name: res.data.name || "",
            });
          });
        }
      });
    },

    /* Simple Registration Methods */
    async manageUserLoggedIn() {
      this.alertUserLoggedIn(this.process.title);
      await this.updateLocalIdeaData();

      if (this.followeSet) {
        if (this.idea?.followedByAuthUser) {
          this.decrementFollower(null);
        } else {
          this.incrementFollower(null);
        }
      }
    },
    manageUserRegisteredToProcess() {
      
      this.alertUserRegisteredToProcess(this.process.title);
    },
    async manageUserLoggedInAndRegisteredToProcess() {
      this.alertUserLoggedInAndRegisteredToProcess(this.process.title);
      await this.updateLocalIdeaData();
    },
    async manageUserLoggedRegisteredToProcess() {
      this.alertUserLoggedRegisteredToProcess(this.process.title);
      await this.updateLocalIdeaData();

      if (this.followeSet) {
        if (this.idea?.followedByAuthUser) {
          this.decrementFollower(null);
        } else {
          this.incrementFollower(null);
        }
      }
    },
    manageRegisterModal() {
      this.$refs["registerOnPublic2"].$refs["modalRegisterOnPublic"].show();
    },
    manageLoginModal() {
      this.$refs["loginRegisterProcessOnPublic"].$refs[
        "modalLoginRegisterProcessOnPublic"
      ].show();
    },
    userHasProcess(value) {
      return this.currentUser.assignedProcesses.includes(value);
    },
    /* End Simple Registration */

    /* Advanced Registration Methods */
    checkUserStatus() {
      let token = JwtService.decodeToken();

      if (this.is_anonymous) {
        // Utente anonimo
        this.$refs["methodAuth"].$refs["modalMethod"].show();
      } else if(!this.can_access_module("vote")) {
        this.alertUserModuleAccess();
      } else if (!this.userHasProcess(this.process.id)) {
        this.$refs["loggedRegisterProcessOnPublic"].$refs[
          "modalLoggedRegisterProcessOnPublic"
        ].show();
      } else if (token.mobileRegStatus == "BLANK") {
        // Utente registrato ma senza cellulare
        this.$refs["roleAuth"].$refs["modalRole"].show();
      } else if (token.mobileRegStatus == "PENDING") {
        // Utente registrato ma con cellulare non confermato
        this.$store.dispatch(RESEND_OTP_CODE, this.currentUser.id);
        this.$refs["mobileVerify"].$refs["modalSms"].show();
      } else if (
        !this.module.taxcodeValidationRequiredForAdvancedRegistration
      ) {
        return true;
      } else if (token.mobileRegStatus == "COMPLETE" && !token.taxcodePresent) {
        // Utente registrato con cellulare confermato ma senza cf
        this.$refs["taxcodeAuth"].$refs["modalCf"].show();
      } else if (token.mobileRegStatus == "COMPLETE" && token.taxcodePresent) {
        // Utente registrato con cellulare confermato e cf formalmento valido
        // Analizzo quindi se questo codice fiscale è autorizzato a votare in questo modulo

        
        let user_enabled = 0;
        if (token.taxcodeP2MStatus.length > 0) {
          token.taxcodeP2MStatus.forEach((value) => {
            // if( value.p2mId == this.module.id && value.status == 'PASSED' ) {
            //   user_enabled = true;
            // }
            if (value.p2mId == this.module.id) {
              if (value.status == "PASSED") {
                user_enabled = 1;
              } else {
                user_enabled = -1;
              }
            }
          });
        }

        
        if (user_enabled == 1) {
          return true;
        } else if (user_enabled == -1) {
          // Utente non abilitato al voto
          this.$refs["taxcodeNotValid"].$refs["modalTaxcodeNotValid"].show();
        } else {
          // Utente non ancora validato sul modulo
          this.$refs["taxcodeAuth"].$refs["modalCf"].show();
        }
      }
      
      return false;
    },

    manageUserRegistered() {
      this.$refs["roleAuth"].$refs["modalRole"].show();
    },
    async manageUserLogged() {
      await this.updateLocalIdeaData();

      if (this.checkUserStatus()) {
        this.manageVoteByFlag();
      }
    },
    manageMobile() {
      this.$refs["mobileAuth"].$refs["modalPhone"].show();
    },
    manageMobileVerify(role) {
      this.user_role = role;
      this.$refs["mobileVerify"].$refs["modalSms"].show();
    },
    manageTaxcode() {
      if (this.module.taxcodeValidationRequiredForAdvancedRegistration) {
        this.$refs["taxcodeAuth"].$refs["modalCf"].show();
      } else {
        // Messaggio di registrazione effettuata
        this.$refs["taxcodeVerified"].$refs["modalTaxcodeVerified"].show();
        if (this.checkUserStatus()) {
          this.manageVoteByFlag();
        }
      }
    },

    manageTaxcodeVerified() {
      this.$refs["taxcodeVerified"].$refs["modalTaxcodeVerified"].show();
      if (this.checkUserStatus()) {
        this.manageVoteByFlag();
      }

      
    },
    /* End Advanced Registration */

    manageOwnerMailed() {
      let proponente = this.idea.author.profilePublic
        ? this.idea.author.name + " " + this.idea.author.surname
        : this.idea.author.username;
      this.alertManageOwnerMailed(proponente);
    },

    managePasswordRecovery() {
      this.$refs["passwordRecovery"].$refs["modalPasswordRecovery"].show();
    },
    manageOTPVerify() {
      this.$refs["passwordMobileVerify"].$refs[
        "modalPasswordMobileVerify"
      ].show();
    },
    async managePasswordChange() {
      await this.manageUserLogged();

      this.$refs["passwordChange"].$refs["modalPasswordChange"].show();
    },

    getAgendaName(agendaId) {
      const agenda = this.agendas.find((x) => x.id == agendaId) || null;
      return agenda ? agenda.name : "";
    },
  },
};
</script>

<style scoped>
.is-valid,
.is-invalid {
  background-image: none !important;
}

.invalid {
  outline: 2px solid red !important;
}

.rect-img-container {
  position: relative;
}

.rect-img-container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.rect-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* togliere cursori da inpute type==number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
